import { useMemo, useState } from 'react'
import { classOption } from 'src/utill/index'
import style from './index.module.scss'
const { classname } = classOption(style)

export default function Global() {
  //data
  //data
  //data
  const [globalContentsList] = useState([
    {
      location: '/images/global/korea.png',
      name: 'S.Korea',
      sub: `4th floor, Teheran-ro 79-gil 25-1,
    Gangnam-gu, Seoul,
    Republic of Korea`,
      subTitle: 'Head Quarter',
    },
    {
      location: '/images/global/usa.png',
      name: 'USA',
      sub: `729 N 1st Street Silicon Valley,
    CA 95112`,
      subTitle: '',
    },
    {
      location: '/images/global/indonesia.png',
      name: 'Indonesia',
      sub: `Level 17, Sequise Tower SCBD JI. Jendral Sudirman No.71 Senayan, Kebayoran Baru, Jakarta Selatan 12190, Indonesia`,
      subTitle: 'Metavitas Teknologi Indonesia',
    },
    {
      location: '/images/global/philippines.png',
      name: 'Philippines',
      sub: `Suite 501 Pasda Mansion Panay Avenue Cor Timog Ave South Triangle 
    1100 Quezon City NCR, Second District, Philippines`,
      subTitle: 'Metavity Philippines Corp.',
    },
    { location: '/images/global/singapore.png', name: 'Singapore', sub: `111 Somerset Road #06-07E 111 Somerset Singapore 238164`, subTitle: '' },
  ])
  //render
  //render
  //render
  const globalContents = useMemo(() => {
    return globalContentsList.map((v, i) => {
      return (
        <div key={`globalContents${i}`} className={classname(['summary-content-wrapper'])}>
          <img className={classname(['summary-content-img'])} src={v.location} alt="country-flag" />
          <div className={classname(['summary-content-header'])}>
            <div className={classname(['summary-content-name'], ['subtitle2'])}>{v.name}</div>
            {v.subTitle && (
              <div className={classname([v.name === 'S.Korea' ? 'summary-content-subtitle-korea' : 'summary-content-subtitle-other', 'summary-content-subtitle'], ['caption'])}>
                {v.subTitle}
              </div>
            )}
          </div>

          <div className={classname(['summary-content-sub'], ['body3'])}>{v.sub}</div>
        </div>
      )
    })
  }, [globalContentsList])
  return (
    <div className={classname(['global'])} style={{ backgroundImage: `url(/images/global/back.png)` }}>
      <div className={classname(['global-title-wrapper'])} style={{ backgroundImage: `url(/images/global/earth.png)` }}>
        <div className={classname(['global-title'], ['h2'])}>Global Branch</div>
      </div>
      <div className={classname(['summary-content-container'])}>{globalContents}</div>
    </div>
  )
}
