import { useRecoilValue } from 'recoil'
import { recoilLangState } from 'src/state/recoilLangState'
import { classOption } from 'src/utill/index'
import style from './index.module.scss'
const { classname } = classOption(style)

export default function MobileAboutUs() {
  const lang = useRecoilValue(recoilLangState)
  return (
    <div className={classname(['mobile-about-us'])}>
      <img className={classname(['about-us-bg'])} src="images/mobile/about-us/about-us-bg.png" alt="background" />
      <div className={classname('about-us-contents-container')}>
        <div className={classname(['about-us-title', 'm-subtitle'])}>About us</div>
        <img className={classname(['about-us-img'])} src="images/mobile/about-us/about-us-img.png" alt="img" />
        <div className={classname(['about-us-text1', 'm-subtitle2'])}>{lang === 'en' ? `Built on values fueled by creativity` : `창의력, 자유로움, 연결성을 핵심가치로`}</div>
        <div className={classname(['about-us-text1', 'm-subtitle2'])}>{lang === 'en' ? `connectivity, and activity.` : ` 21세기에 살아갈 역량을 계발합니다.`}</div>
        <div className={classname(['about-us-text2', 'm-body2'])}>
          {lang === 'en'
            ? `The problem with current education system at the global and national level is that industry and education are operating in their own way. Our reality is that teachers of
          the 20th century are teaching children of the 21st century in a curriculum and educational environment of the 19th century.`
            : `현재 국내를 포함한 글로벌 교육의 문제점은 교육과 산업이 따로따로 움직인다는 것입니다. 20세기의 교사들이 19세기의 교육과정으로 21세기 아이들을 가르치고 있어 21세기에 걸 맞는 교육시스템이 절실히 요구되고 있습니다.`}
          <br />
          <br />
          {lang === 'en'
            ? `Metavity intends to open the age of e-learning 3.0 through the metaverse platform, which is a metaverse-type school for fostering outstanding human resources with
          creative and convergent thoughts and ideas, not to mention assisting the self-realization of people in a third virtual world and helping them to acquire the necessary
          capacities and knowledge for the development and subsistence of humanity.`
            : `메타비티가 만드는 메타버스는 제3의 가상세계에서 현실세계에 필요한 지식과 역량을 습득함과 동시에 창의적이고 융합적인 사고를 가진 우수한 인재를 양성하기 위한 e러닝 웹3.0 메타버스 플랫폼입니다. `}
        </div>
      </div>
    </div>
  )
}
