/* states/recoilCounterState.ts */

import { atom } from 'recoil'

export type LangState = {
  lang: string
}

const initialState: string = 'ko'

export const recoilLangState = atom({
  key: 'recoilCounterState',
  default: initialState,
})
