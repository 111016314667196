import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { recoilLangState } from 'src/state/recoilLangState'
import { classOption } from 'src/utill/index'
import style from './index.module.scss'
const { classname } = classOption(style)

export default function MobileGlobal() {
  const lang = useRecoilValue(recoilLangState)
  const globalEduData = useMemo(() => {
    return [
      { name: 'Stanford SMILE', icon: '/images/mobile/global-edu/1.png' },
      { name: 'Silicon Valley Math', icon: '/images/mobile/global-edu/2.png' },
      { name: 'Science & STEAM', icon: '/images/mobile/global-edu/3.png' },
      { name: 'K-Culture, \nK-Pop & K-Food', icon: '/images/mobile/global-edu/4.png' },
      { name: 'Golf & Sports for All ', icon: '/images/mobile/global-edu/5.png' },
      {
        name: `Government Accredited\nComputer Certificate`,
        icon: '/images/mobile/global-edu/6.png',
      },
      { name: 'ISO Disaster\nSafety Certificate', icon: '/images/mobile/global-edu/7.png' },
      { name: 'Multiple Language', icon: '/images/mobile/global-edu/8.png' },
      { name: 'Financial Intelligence', icon: '/images/mobile/global-edu/9.png' },
      { name: 'Problem Solving', icon: '/images/mobile/global-edu/10.png' },
    ]
  }, [])

  const globalEduMap = useMemo(() => {
    return globalEduData.map((v, i) => {
      return <MobileIconBox name={v.name} icon={v.icon} key={`Mobile globalEduMapping-${i}`} />
    })
  }, [globalEduData])

  return (
    <div className={classname('mobile-global')}>
      <div className={classname(['global-title'], ['m-subtitle'])}>Global hub in Education</div>
      <div className={classname(['global-subtitle'], ['m-subtitle2'])}>
        {lang === 'en' ? `Acquire 100 Million Users by 2024 \nand 1 Billion Users by 2028` : `2024년까지 1억명, \n2028년까지 10억 명의 사용자 확보`}
      </div>
      <div className={classname(['global-description'], ['m-body2'])}>
        {lang === 'en'
          ? ` Excellent Educational Contents & Services + Big data-based AI analysis & Recommendation Technology, Learner’s Behavior Data and Ensuring Data Autonomy of Learners through
        Blockchain + Stanford SMILE`
          : `우수한 교육 콘텐츠 및 서비스 + 빅데이터 기반 AI 분석 및 추천 기술, 학습자 행동 데이터 및 블록체인을 통한 학습자의 데이터 자율성 보장 + Stanford SMILE`}
        <br />
        <br />
        {lang === 'en'
          ? `For the past 11 years, we have been working with local EdTech partners with competitiveness and market dominance in each region while conducting various global activities
        and businesses.`
          : `지난 11년간 다양한 글로벌 사업을 진행하며 현지에서 경쟁력과 시장지배력을 갖춘 에드테크 파트너들과 협력해 왔습니다.`}
      </div>
      <div className={classname(['global-edu-map-container'])}>{globalEduMap}</div>
    </div>
  )
}

type _MobileIconBox = {
  name: string
  icon: string
}

export const MobileIconBox: React.FC<_MobileIconBox> = ({ name, icon }) => {
  return (
    <div className={classname('icon-box-container')}>
      <div className={classname('icon-box')}>
        <img src={icon} className={classname('icon')} alt="icon" />
      </div>
      <div className={classname(['icon-text'], ['m-body2'])}>{name}</div>
    </div>
  )
}
