import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { recoilLangState } from 'src/state/recoilLangState'
import { classOption } from 'src/utill/index'
import axios from 'axios'
import dayjs from 'dayjs'
import { toNumber } from 'lodash'
import Pagination from './Pagination'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import style from './index.module.scss'
import CryptoJS from 'crypto-js'


const { classname } = classOption(style)

export default function News() {

  const password = '5ca6f2f5858050415d401261988955d7e53ebe50eeac08a6b3f5dd0ef04f3b41'


  const [limit, setLimit] = useState(5);
  const [page1, setPage1] = useState(1);
  const [page2, setPage2] = useState(1);
  const offset1 = (page1 - 1) * limit;
  const offset2 = (page2 - 1) * limit;

  const [idx, setIdx] = useState(0);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [link, setLink] = useState("");
  const [type, setType] = useState("");
  const [regDate, setRegDate] = useState("");

  const [boardList1, setBoardList1] = useState([{
    BOARD_IDX: '',
    BOARD_TITLE: '',
    REG_DATE: '',
  }])

  const [boardList2, setBoardList2] = useState([{
    BOARD_IDX: '',
    BOARD_TITLE: '',
    REG_DATE: '',
  }])

  const [viewLayer, setViewLayer] = useState<boolean>(false) // 모달
  const [editLayer, setEditLayer] = useState<boolean>(false) // 모달


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/boardList1`)
      .then(
        (res) => {
          setBoardList1(res.data)
        }
      )
      .catch(error => console.log(error))
  },[])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/boardList2`)
      .then(
        (res) => {
          setBoardList2(res.data)
        }
      )
      .catch(error => console.log(error))
  },[])


  const getBoard = (boardId : number) => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/boardView/${boardId}`)
      .then((res) => {
        setIdx(res.data[0].BOARD_IDX)
        setTitle(res.data[0].BOARD_TITLE)
        setContent(res.data[0].BOARD_CONTENTS)
        setType(res.data[0].BOARD_TYPE)
        setRegDate(res.data[0].REG_DATE)
        setLink(res.data[0].BOARD_LINK)
      })
      .catch(error => console.log(error))
  }

  const removeBoard = () => {
    const data = {idx}

    axios.post(`${process.env.REACT_APP_API_URL}/api/board/remove`, data).then((res) => {

      if (res.status === 200) {
        alert("게시글이 삭제되었습니다.");
        window.location.reload();
        return;

      } else {

        alert("수정 실패.");
        return;
      }
    });
  }

  const boardRemove = () => {

    if (window.confirm('삭제하시겠습니까?')) {
      removeBoard();
    }
  }

  const boardSubmit = () => {

    if(title.length < 1){
      alert("제목을 입력해주세요")
      return;
    }

    if (type === '') {
      alert("유형을 선택해주세요")
      return;
    }

    if(content.length < 1){
      alert("내용을 입력해주세요")
      return;
    }

    const data = {
      idx,
      title,
      link,
      type,
      content,
    };

    if(idx === 0){

      axios.post(`${process.env.REACT_APP_API_URL}/api/board/reg`, data).then((res) => {

        if (res.status === 200) {
          alert("게시글이 등록되었습니다.");
          window.location.reload();
          return;

        } else {

          alert("등록 실패.");
          return;
        }
      });

    }else{


      axios.post(`${process.env.REACT_APP_API_URL}/api/board/update`, data).then((res) => {

        if (res.status === 200) {
          alert("게시글이 수정되었습니다.");
          window.location.reload();
          return;

        } else {

          alert("수정 실패.");
          return;
        }
      });


    }



  };

  function editLayerOpen(idx : number | null){

    if(idx != null){
      getBoard(idx);
    }

    setEditLayer(true)
  }

  function editLayerClose() {
    setEditLayer(false);
    setIdx(0)
    setTitle("");
    setType("");
    setLink("");
    setContent("");
    setRegDate("");
  }


  function passwordCheck(){

    let sign = prompt("비밀번호 입력");

    if(sign != null){
      sign =  CryptoJS.SHA256(sign).toString();

      if(sign !== password){
        alert("패스워드 오류")
        return false
      }else{
        return true
      }
    }else{
      return false
    }

  }

  function reg(){
    if(passwordCheck()){
      editLayerOpen(null)
    }
  }

  function edit(idx : number){
    if(passwordCheck()){
      setIdx(idx);
      viewLayerClose();
      editLayerOpen(idx);
    }
  }

  function remove(idx : number){
  }





  function viewLayerOpen(boardId : number) {
    getBoard(boardId);
    setViewLayer(true)
  }

  function viewLayerClose() {
    setViewLayer(false);
    setIdx(0)
    setTitle("");
    setLink("");
    setType("");
    setContent("");
    setRegDate("");
  }

  const lang = useRecoilValue(recoilLangState)

  //render
  return (


    <div className={classname(['news'])}>


      <div className={classname(['news-wrapper'])}>

        <div className={classname(['news-list'])}>

          <div className={classname(['news-item'])}>

            <div className={classname(['news-title'])}>
              <div className={classname(['title-box'])}>
                {lang === 'en' ? `PR` : `PR`}
              </div>

              <div className={classname(['option-box'])}>
                <button type={'button'} onClick={ () => reg()}>작성</button>
              </div>
            </div>

            <div className={classname(['news-contents'])}>

              <div className={classname(['table-box'])}>

                {boardList1.length === 0 ? (
                    <div className={classname(['nodata'])}>
                      {lang === 'en' ? 'no data' : '등록된 게시글이 없습니다.'}
                    </div>
                  ) : (
                    <table className={classname()}>

                      <colgroup>
                        <col style={{ width: `15%` }} />
                        <col style={{ width: `60%` }} />
                        <col style={{ width: `25%` }} />
                      </colgroup>

                      <thead>
                      <tr>
                        <th>번호</th>
                        <th>제목</th>
                        <th>날짜</th>
                      </tr>
                      </thead>

                      <tbody>

                      {
                        boardList1.slice(offset1, offset1 + limit).map((board, index) => {
                          return (
                            <tr key={board.BOARD_IDX} onClick={() => viewLayerOpen(toNumber(board.BOARD_IDX))}>
                              <td>{boardList1.length - index - offset1}</td>
                              <td>
                                <div className={classname(['eclipse-box'])}>{board.BOARD_TITLE}</div>
                              </td>
                              <td>{dayjs(board.REG_DATE).format('YYYY-MM-DD')}</td>
                            </tr>
                          )
                        })
                      }

                      </tbody>

                    </table>
                  )
                }

              </div>

              <div className={classname(['paging-box'])}>
                <Pagination
                  total={boardList1.length}
                  limit={limit}
                  page={page1}
                  setPage={setPage1}
                />
              </div>


            </div>

          </div>

          <div className={classname(['news-item'])}>

            <div className={classname(['news-title'])}>
              <div className={classname(['title-box'])}>
                {lang === 'en' ? `NEWS` : `언론보도`}
              </div>

              <div className={classname(['option-box'])}>
                <a href='#none'>작성</a>
              </div>
            </div>

            <div className={classname(['news-contents'])}>



            <div className={classname(['table-box'])}>


              {boardList2.length === 0 ? (
                  <div className={classname(['nodata'])}>
                    {lang === 'en' ? 'no data' : '등록된 게시글이 없습니다.'}
                  </div>
                ) : (
                  <table className={classname()}>

                    <colgroup>
                      <col style={{ width: `15%` }} />
                      <col style={{ width: `60%` }} />
                      <col style={{ width: `25%` }} />
                    </colgroup>

                    <thead>
                    <tr>
                      <th>번호</th>
                      <th>제목</th>
                      <th>날짜</th>
                    </tr>
                    </thead>

                    <tbody>

                    {boardList2.slice(offset2, offset2 + limit).map((board, index) => {
                      return (
                        <tr key={board.BOARD_IDX} onClick={() => viewLayerOpen(toNumber(board.BOARD_IDX))}>
                          <td>{boardList2.length - index - offset2}</td>
                          <td>
                            <div className={classname(['eclipse-box'])}>{board.BOARD_TITLE}</div>
                          </td>
                          <td>{dayjs(board.REG_DATE).format('YYYY-MM-DD')}</td>
                        </tr>
                      )
                    })}
                    </tbody>

                  </table>
                )
              }


            </div>

              <div className={classname(['paging-box'])}>
                <Pagination
                  total={boardList2.length}
                  limit={limit}
                  page={page2}
                  setPage={setPage2}
                />
              </div>

            </div>

          </div>

        </div>

        {
          viewLayer && <div className={classname('view-layer')}>
            <div className={classname('layer-wrapper')}>
              <div id='modal' className={classname('layer-contents')}>

                <div className={classname('contents-head-box')}>
                  <div className={classname('head-box')}>{title}</div>
                  <div className={classname('sub-box')}>{dayjs(regDate).format('YYYY-MM-DD')}</div>
                </div>

                {link !== null &&
                  <div className={classname('contents-sub-box')}>
                    <span className={classname('label-txt')}>자료링크</span>
                   <a href={link} className={classname('link-txt')}  target={'_blank'}>{link}</a>
                  </div>
                }


                <div className={classname('contents-body-box')}>

                  <div className={classname('contents-view')}>

                    <div className={classname('ck-content')}
                         dangerouslySetInnerHTML={{ __html: content }}>
                    </div>

                  </div>


                </div>

              </div>
            </div>

            <div className={classname('layer-menu')}>
              <div className={classname('dont-see')} onClick={viewLayerClose}>닫기</div>

              <button type={'button'} className={classname('remove-btn')} onClick={() => remove(toNumber(idx))}></button>

              <button type={'button'} className={classname('edit-btn')} onClick={() => edit(toNumber(idx))}></button>

            </div>

          </div>
        }

        {
          editLayer && <div className={classname('view-layer')}>
            <div className={classname('layer-wrapper')}>
              <div id='modal' className={classname('layer-contents')}>

                <div className={classname('contents-head-box')}>

                  <div className={classname(['input-box'])}>
                    <input
                      value={title || ''}
                      onChange={(e) => setTitle(e.target.value)}
                      // onChange={onChangeTitle}
                      placeholder='제목을 입력하세요'
                    />
                  </div>

                  <div className={classname(['select-box'])}>
                    <select
                      key={type}
                      defaultValue={type}
                      onChange={(e) => setType(e.target.value)}>
                      <option key={''} value={''}>유형</option>
                      <option key={'PR'} value={'PR'}>PR</option>
                      <option key={'NEWS'} value={'NEWS'}>언론보도</option>
                    </select>
                  </div>

                </div>

                <div className={classname('contents-sub-box')}>
                  <div className={classname(['input-box'])}>
                    <input
                      value={link || ''}
                      onChange={(e) => setLink(e.target.value)}
                      // onChange={onChangeTitle}
                      placeholder='참고 링크주소를 입력하세요'
                    />
                  </div>

                </div>


                <div className={classname('contents-edit-box')}>

                  <div className={classname('contents-view')}>

                    <CKEditor
                      editor={ClassicEditor}
                      data={content}
                      config={
                        {
                          toolbar: {
                            items: [  'undo', 'redo', '|', 'bold', 'italic', '|', 'numberedList', 'bulletedList' , 'insertTable' , 'link'],
                         }
                        }
                      }
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setContent(data);
                      }}

                    />
                  </div>

                </div>

                <div className={classname(['contents-menu-box'])}>

                  <button type={'button'} onClick={boardSubmit}>
                    {idx === 0 ? '작성' : '수정'}
                  </button>

                  {idx !== 0 &&
                    <button type={'button'} onClick={boardRemove}>삭제</button>
                  }



                </div>

              </div>

            </div>
            <div className={classname('dont-see')} onClick={editLayerClose}>닫기</div>
          </div>
        }


      </div>
    </div>


  )
}



