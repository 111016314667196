import style from './index.module.scss'
import { classOption } from 'src/utill/index'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { recoilLangState } from 'src/state/recoilLangState'

const { classname } = classOption(style)

const Slider = () => {
  const lang = useRecoilValue(recoilLangState)
  const data = useMemo(() => {
    return [
      {
        name: 'Teaching',
        text: lang === 'en' ? 'Encouraging future growth mindset in a digital era with cutting edge technology' : ' IT 최첨단 기술로 디지털 시대의 미래역량을 가르칩니다',
        location: '/images/mobile/mission/mission-01.png',
        number: '/images/mobile/mission/01.png',
      },
      {
        name: 'Learning',
        text:
          lang === 'en'
            ? 'Delivering effective learning by optimizing synchronous and asyn-chronous learning strategies'
            : '동기와 비동기식 전략을 통해 효과적인 러닝방법을 제공합니다.',
        location: '/images/mobile/mission/mission-02.png',
        number: '/images/mobile/mission/02.png',
      },
      {
        name: 'Sharing',
        text:
          lang === 'en'
            ? 'Putting publishing into the hands of learners. Nurture creativity and collaboration with publishing'
            : '단순 학습자에서 나아가 공간을 누리는 모두가 협업을 통해 창의적 생산력을 키우고 콘텐츠 재생산에도 참여합니다.',
        location: '/images/mobile/mission/mission-03.png',
        number: '/images/mobile/mission/03.png',
      },
    ]
  }, [lang])
  const imageList = useMemo(() => {
    return [...data, ...data, data[0], data[1]]
  }, [data])
  const [currentSlide, setCurrentSlide] = useState(0)
  const [transitionSlide, setTransitionSlide] = useState(0)

  const slideRef = useRef<HTMLDivElement>(null)

  const imagesList = useMemo(() => {
    return imageList.map((v, i) => {
      return (
        <img
          onClick={() => {
            if (currentSlide !== i) {
              setCurrentSlide(currentSlide + 1)
              setTransitionSlide(transitionSlide + 1)
            }
          }}
          className={classname([currentSlide === i ? 'slider-img' : 'slider-img-opacity'])}
          key={`mission-img-slider${i}`}
          src={v.location}
          alt=""
        />
      )
    })
  }, [currentSlide, imageList, transitionSlide])
  useEffect(() => {
    if (currentSlide === 3) {
      setTimeout(() => {
        setCurrentSlide(0)
        setTransitionSlide(0)
      }, 500)
    }
  }, [currentSlide, imageList?.length])

  return (
    <div className={classname(['container'])}>
      <div
        className={classname(['slider-container'])}
        style={{ transform: `translateX(-${currentSlide * 290}px)`, transition: transitionSlide === 0 ? `0ms` : `all 0.4s ease-in-out` }}
        ref={slideRef}>
        {imagesList}
      </div>
      <div className={classname(['description'])}>
        <img className={classname(['number'])} src={imageList[currentSlide].number} alt="number" />
        <div className={classname(['name'], ['body1'])}>{imageList[currentSlide].name}</div>
        <div className={classname(['text'], ['m-body2'])}>{imageList[currentSlide].text}</div>
      </div>
    </div>
  )
}

export default Slider
