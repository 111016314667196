import { useRecoilValue } from 'recoil'
import { recoilLangState } from 'src/state/recoilLangState'
import { classOption } from 'src/utill/index'
import style from './index.module.scss'
const { classname } = classOption(style)
export default function AboutUs() {
  const lang = useRecoilValue(recoilLangState)
  return (
    <div className={classname('aboutus')} style={{ backgroundImage: `url(/images/aboutus/back.png)` }}>
      <div className={classname('img-wrapper')}>
        <img className={classname('img')} src="/images/aboutus/aboutus.png" alt="aboutus" />
      </div>
      <div className={classname('text-wrapper')}>
        <div className={classname(['title'], ['body1'])}>About us</div>
        <div className={classname(['main-title'], ['h2'])}>
          {lang === 'en' ? (
            <span>
              <span>Built on values</span>
              <br></br>
              <span>fueled by creativity</span>
              <br></br>
              <span>connectivity, and activity.</span>
            </span>
          ) : (
            <span>
              <span>창의력, 자유로움, 연결성을</span> <br></br>
              <span>핵심가치로 21세기에</span> <br></br>
              <span> 살아갈 역량을 계발합니다.</span>
            </span>
          )}
        </div>
        <div className={classname(['sub1'], ['body3'])}>
          {lang === 'en'
            ? `The problem with current education system at the global and national level is 
that industry and education are operating in their own way. 
Our reality is that teachers of the 20th century are teaching children of the 21st century in a curriculum and educational environment of the 19th century.`
            : `현재 국내를 포함한 글로벌 교육의 문제점은 교육과 산업이 따로따로 움직인다는 것입니다. 20세기의 교사들이 19세기의 교육과정으로 21세기 아이들을 가르치고 있어 21세기에 걸 맞는 교육시스템이 절실히 요구되고 있습니다.`}
          <br></br>
          <br></br>
          <br></br>
          {lang === 'en'
            ? `Metavity intends to open the age of e-learning 3.0 through the metaverse platform, 
which is a metaverse-type school for fostering outstanding human resources 
with creative and convergent thoughts and ideas, 
not to mention assisting the self-realization of people in a third virtual world 
and helping them to acquire the necessary capacities and knowledge 
for the development and subsistence of humanity.`
            : `메타비티가 만드는 메타버스는 제3의 가상세계에서 현실세계에 필요한 지식과 역량을 습득함과 동시에 창의적이고 융합적인 사고를 가진 우수한 인재를 양성하기 위한 e러닝 웹3.0 메타버스 플랫폼입니다.`}
        </div>
      </div>
    </div>
  )
}
