import useMobileDetect from './hooks/commons/mobileDetect'
import RootPage from './pages'
import MobileWeb from './pages/mobile'


function App() {
  const { checkProcessing, isMobile } = useMobileDetect()

  if (checkProcessing) return <></>
  if (isMobile) return <MobileWeb />
  return <RootPage />
}

export default App
