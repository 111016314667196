import { useEffect, useState } from 'react'

const useIsLoad = () => {
  const [isLoad, setIsLoad] = useState(false)
  useEffect(() => {
    setIsLoad(true)
  }, [])

  return isLoad
}

export default useIsLoad
