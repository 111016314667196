import MobileAboutUs from 'src/components/mobile/about-us'
import MobileCompanySummary from 'src/components/mobile/company-summary'
import MobileFooter from 'src/components/mobile/footer'
import MobileGlobal from 'src/components/mobile/global'
import MobileGlobalBranch from 'src/components/mobile/global-branch'
import MobileHeader from 'src/components/mobile/header'
import MobileMain from 'src/components/mobile/main'
import MobileMission from 'src/components/mobile/mission'
import MobileOurTeam from 'src/components/mobile/ourteam'
import MobilePlatform from 'src/components/mobile/platform'
// import Modal from 'src/components/modal'
import { classOption } from '../../utill'
import style from './index.module.scss'
import MobileNews from '../../components/mobile/news'
import Modal from '../../components/modal'

import Viewer from 'viewerjs'
import { useEffect } from 'react'


const { classname } = classOption(style)

const MobileWeb = () => {

  // useEffect(() => {
  //   const viewer = new Viewer(document.getElementById('modal') as HTMLElement, {
  //     // 여기에 Viewer.js 옵션을 추가할 수 있습니다.
  //   });
  //
  //   return () => {
  //     viewer.destroy();
  //   }
  // }, [])


  return (
    <div className={classname('Mobile')}>
      <MobileHeader />
      <MobileMain />
      <MobileAboutUs />
      <MobileNews />
      <MobileMission />
      <MobileGlobal />
      <MobileGlobalBranch />
      <MobileCompanySummary />
      <MobilePlatform />
      <MobileOurTeam />
      <MobileFooter />
      {/* <Modal>
        <div className={classname('modal-img')}>
          <img style={{ maxWidth: '100%', maxHeight: '100%', cursor: 'pointer', borderRadius: '20px' }} src={'images/modal/sul.jpg'} alt="modalImg" />
        </div>
      </Modal> */


        // <Modal>
        //   <div id="modal" className={classname('modal-txt')}>
        //     {/*<div className={classname('modal-txt-group')} data-modal="modal1">*/}
        //     {/*  <div className={classname('modal-txt-contents')} style={{maxHeight : '50vh'}}>*/}
        //     {/*    [긴급 공지]<br/><br/>*/}
        //
        //     {/*    최근 불법적인 업체로부터 주식 사기 판매 사례가 빈번하게 일어나고 있어서 회사가 피해를 입고 있습니다. 내용 증명서 등 사문서를 위조하고 상장 확정된 내용으로 허위 기사를 임의로 내고 투자 권유를 하는 등의 회사와 무관하게 주식 거래를 유도해 피해자들을 만들고 있습니다. 회사에서 공식적으로 기관과 개인에게 투자를 받고 있는 주당 3,000원 초과의 거래는 사기 거래일 가능성이 매우 큽니다. 현재 사측은 불법적인 업체들이 확인되는 대로 고소, 고발을 진행 중에 있으며 무관용 원칙을 적용하여 강경 대응 조치를 취하고 있습니다. 허위 기사를 낸 언론사와 기자는 내용 증명을 보내고 고소를 진행 중에 있습니다. 위의 피해 사례와 본사는 전혀 무관함을 다시 한번 알려드리며, 위의 피해 사례에 대해 집단소송을 진행하는 법무법인이 있으나 피해자들과 확인해 본 결과, 선결제로 사례비를 요구하고 고소장 접수만 대리할 뿐 소송 대상을 특정할 수 없기 때문에 소송 진행도 어렵다는 것이 확인되었습니다. 이 사건은 경찰서에서 불법 업체들을 추적조사하고 있는 중인 사건입니다. 개별적으로 고소하시는 것이 추가적인 비용 지출으로 2차, 3차 피해를 입는 것을 막는 것임을 알려드립니다.*/}
        //
        //
        //     {/*  </div>*/}
        //
        //     {/*</div>*/}
        //
        //     {/*<div className={classname('modal-txt-group')} data-modal="modal2">*/}
        //     {/*  <div className={classname('modal-txt-contents')} style={{maxHeight: '30vh', padding:0}}>*/}
        //     {/*    <img src="/images/popup/popup_231229.jpg" style={{maxWidth: '900px' , width: '100%'}} alt=""/>*/}
        //     {/*  </div>*/}
        //     {/*</div>*/}
        //
        //     <div className={classname('modal-txt-group')} data-modal="modal3">
        //       <div className={classname('modal-txt-contents')} style={{maxHeight: '85vh', padding:0}}>
        //         <img src="/images/popup/popup_240617.jpg" style={{maxWidth: '900px' , width: '100%'}} alt=""/>
        //       </div>
        //     </div>
        //
        //
        //
        //     {/*<div className={classname('modal-txt-group')} data-modal="modal2">*/}
        //     {/*  <div className={classname('modal-txt-contents')}>*/}
        //     {/*    안녕하세요, 메타비티 주주님들께 안내 사항을 전하고자 합니다. 앞으로 주주님들과 원활한 소통을 위하여 향후 메타비티 관련 뉴스 및 진행 상황 등을 이메일을 통하여 소식을 전달하고자 합니다. 그 동안 소통 부분에 있어서 궁금하셨을 주주님들을 위하여 현재 사측에서는 순차적으로 시스템 및 메뉴얼 보완을 진행 중이고, 최종 마무리가 되기 전까지는 각 주주님들 개별적으로 사측 관련된 소식 및 레터 등을 발송해드리려 하오니, 아래의 링크 주소를 클릭하여 설문지에 응답하여 주시길 당부 드립니다. 감사합니다.<br/><br/>*/}
        //
        //     {/*    ● 설문조사 링크 주소<br/><br/>*/}
        //     {/*    <a href="https://docs.google.com/forms/d/e/1FAIpQLScXr3G0DhP4krAougsSlVqKmXN03puD9w4sw3VKC-XkWFDpdA/viewform?usp=sf_link" target="_blank" className={classname('link-txt')}>https://docs.google.com/forms/d/e/1FAIpQLScXr3G0DhP4krAougsSlVqKmXN03puD9w4sw3VKC-XkWFDpdA/viewform?usp=sf_link</a>*/}
        //     {/*  </div>*/}
        //
        //     {/*</div>*/}
        //
        //     {/*<div className={classname('modal-txt-group')} data-modal="modal3">*/}
        //     {/*  <div className={classname('modal-txt-contents')}>*/}
        //     {/*    안녕하세요, 주식회사 메타비티입니다.<br/><br/>*/}
        //
        //     {/*    최근 투자 관련 통화 문의량이 급증하여 유관 부서를 비롯한 회사 전체에 심각한 업무 지장 및 피해가 발생하고 있습니다.<br/><br/>*/}
        //
        //     {/*    현재 사측은 관련 부서 인원 확충 및 조직 개편과 응대 시스템 정비 등을 통하여, 조속한 시일 안에 유선 문의 또한 정상화 처리를 취할 예정입니다. 이로 인하여 당분간 모든 문의사항은 회사 공식 이메일 support@metavity.world 로 문의 주시기를 당부의 말씀을 드립니다.<br/><br/>*/}
        //
        //     {/*    감사합니다.*/}
        //
        //
        //     {/*  </div>*/}
        //
        //     {/*</div>*/}
        //
        //   </div>
        // </Modal>

      }



    </div>
  )
}



export default MobileWeb
