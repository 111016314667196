import { AnimatePresence, motion } from 'framer-motion'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import { classOption } from 'src/utill'
import style from './main-slider.module.scss'
const { classname } = classOption(style)

type props = {
  className?: string
  gap?: number
}
export default function MainSlider({ className = '', gap = 20 }: props) {
  const [imagesList] = useState(
    [
      { src: '/images/main/image1.png', alt: 'image1' },
      { src: '/images/main/image2.png', alt: 'image2' },
      { src: '/images/main/image3.png', alt: 'image3' },
      { src: '/images/main/image4.png', alt: 'image4' },
      { src: '/images/main/image5.png', alt: 'image5' },
    ].map((v, i) => {
      let newItem = { ...v } as typeof v & { id: string }
      newItem.id = `imageSlider-item${i + 1}`
      return newItem
    }),
  )
  const [targetImageIdx, setImageIdx] = useState(2)
  const center = useMemo(() => Math.floor(imagesList.length / 2), [imagesList.length])
  const currentImageIdx = useMemo(() => targetImageIdx % imagesList.length, [imagesList.length, targetImageIdx])

  const calcImageList = useMemo(() => {
    return _(imagesList)
      .map((v, i, o) => {
        return _(o).nth(i - currentImageIdx)!
      })
      .value()
  }, [currentImageIdx, imagesList])

  const displayImages = useMemo(() => {
    return _(calcImageList)
      .map((v, i) => {
        let isCenter = center === i
        let isCenterNext = center + 1 === i
        return (
          <motion.div
            layout
            className={classname(['slider-item'])}
            key={`${v.id}-${Math.floor((targetImageIdx - (i + 1)) / calcImageList.length)}`}
            initial={{ opacity: 0, scale: 1 }}
            animate={{ ...(isCenter ? { scale: 2, zIndex: 2, marginLeft: 0, marginRight: 0 } : { zIndex: isCenterNext ? 1 : 'auto' }), opacity: 1 }}
            exit={{ width: 0, flex: 'none', opacity: 0, marginRight: -gap }}
            transition={{ default: { ease: 'easeInOut', duration: 0.7 }, opacity: { ease: 'linear', duration: 0.5 } }}>
            <img width={'100%'} src={v.src} alt={v.alt} />
          </motion.div>
        )
      })
      .value()
  }, [calcImageList, center, gap, targetImageIdx])

  const calcZoomImageList = useMemo(() => {
    return _(calcImageList)
      .slice(center - 1, center + 2)
      .value()
  }, [calcImageList, center])

  const displayZoomImage = useMemo(() => {
    return _(calcZoomImageList)
      .map((v, i) => {
        return (
          <motion.img
            className={`${v.id}`}
            key={`${v.id}`}
            layout
            width="100%"
            src={v.src}
            alt={v.alt}
            transition={{ default: { ease: 'easeInOut', duration: 0.7 }, opacity: { ease: 'linear', duration: 0.5 } }}
          />
        )
      })
      .value()
  }, [calcZoomImageList])

  useEffect(() => {
    let id: NodeJS.Timer
    const fn = () => {
      id = setTimeout(() => {
        setImageIdx((s) => s + 1)
        fn()
      }, 3000)
    }
    fn()
    return () => {
      clearTimeout(id)
    }
  }, [])

  return (
    <div className={classname(['image-slider-wrapper'], [className])}>
      <div className={classname(['slider'])}>
        <AnimatePresence>{displayImages}</AnimatePresence>
      </div>
      <div className={classname(['tablet'])}>
        <div className={classname(['tablet-inner'])}>{displayZoomImage}</div>
        <div className={classname(['tablet-img'])} />
      </div>
    </div>
  )
}
