import { classOption } from 'src/utill/index'
import style from './index.module.scss'
import { useRecoilState } from 'recoil'
import { recoilLangState } from 'src/state/recoilLangState'
const { classname } = classOption(style)

export default function Header() {
  const [lang, setLang] = useRecoilState(recoilLangState)
  const onHomeClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  const onAboutUsClick = () => {
    window.scrollTo({
      top: 1000,
      behavior: 'smooth',
    })
  }
  const onNewsClick = () => {
    window.scrollTo({
      top: 2020,
      behavior: 'smooth',
    })
  }
  const onPlatformsClick = () => {
    window.scrollTo({
      top: 7070,
      // top: 6450,
      behavior: 'smooth',
    })
  }
  const onOurTeamClick = () => {
    window.scrollTo({
      top: 8070,
      behavior: 'smooth',
    })
  }

  const onContactUsClick = () => {
    window.scrollTo({
      top: 9147,
      behavior: 'smooth',
    })
  }
  return (
    <div className={classname(['header'])}>
      <img className={classname(['logo'])} onClick={onHomeClick} src="/images/header/logo.png" alt="logo" />

      <div className={classname(['tab'], ['body3'])}>
        <div className={classname(['tab-item'])} onClick={onAboutUsClick}>
          About Us
        </div>
        <div className={classname(['tab-item'])} onClick={onNewsClick}>
          News
        </div>
        <div className={classname(['tab-item'])} onClick={onPlatformsClick}>
          Platforms
        </div>
        <div className={classname(['tab-item'])} onClick={onOurTeamClick}>
          Our Team
        </div>
        <div className={classname(['tab-item'])} onClick={onContactUsClick}>
          Contact us
        </div>
      </div>
      <div className={classname(['lang'], ['caption'])}>
        <div
          className={classname([lang === 'ko' ? 'bold' : 'opacity'])}
          onClick={() => {
            setLang('ko')
          }}>
          KOR
        </div>
        <div
          className={classname([lang === 'en' ? 'bold' : 'opacity'])}
          onClick={() => {
            setLang('en')
          }}>
          ENG
        </div>
      </div>
    </div>
  )
}
