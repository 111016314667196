import { RefObject, useCallback, useEffect, useState } from 'react'

export default function useRefWidth(ref: RefObject<HTMLElement>) {
  const [width, setWidth] = useState(0)

  const recur = useCallback(
    (obsv: ResizeObserver) => {
      if (ref.current) {
        setWidth(ref.current.clientWidth)
        obsv.observe(ref.current)
      } else {
        setTimeout(() => {
          recur(obsv)
        }, 100)
      }
    },
    [ref],
  )

  useEffect(() => {
    const obsv = new ResizeObserver((entries) => {
      if (ref.current) {
        setWidth(ref.current.clientWidth)
      }
    })
    recur(obsv)
    return () => {
      obsv.disconnect() 
    }
  }, [ref, recur])

  return width
}
