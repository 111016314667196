import { classOption } from 'src/utill/index'
import style from './index.module.scss'
import Slider from './slider'
const { classname } = classOption(style)

export default function MobileMission() {
  return (
    <div className={classname(['mobile-mission'])}>
      <div className={classname('mission-contents-container')}>
        <div className={classname('mission-contents-header')}>
          <div className={classname(['mission-title', 'm-subtitle'])}>Mission</div>
          <div className={classname(['mission-subtitle', 'm-subtitle2'])}>Paradigm Shift in Education</div>
        </div>
        <div className={classname('mission-carousel-container')}>
          <Slider />
        </div>
      </div>
    </div>
  )
}
