import _ from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import useRefWidth from 'src/hooks/useRefWidth'
import { recoilLangState } from 'src/state/recoilLangState'
import { classOption } from 'src/utill/index'
import style from './index.module.scss'

const { classname } = classOption(style)

export default function MobileOurTeam() {
  // data
  // data
  // data
  const lang = useRecoilValue(recoilLangState)
  const images = useMemo(() => {
    return [
      {
        location: '/images/ourteam/ceo.png',
        name: lang === 'en' ? 'Ryan Byun' : '변원섭',
        position: 'CEO',
        career:
          lang === 'en'
            ? [
                'Education system and content design specialist',
                'SMILE Global Vice-President',
                'International Math Olympiad (WMO), Committee Member',
                'Previously',
                'CMS EDU, CD Learning M&A KOSDAQ IPO Design',
                'Math Convergence Education and Design',
                'GLMAC School, Planning Manager',
                'Seoul National University, College of Education',
              ]
            : [
                '한국 NFT 콘텐츠협회 교육분과위원장',
                'SMILE Global Vice-President',
                '세계수학올림피아드(WMO)조직위원회위원',
                'Previously',
                'CMS EDU CSO',
                'CMS EDU, 청담러닝M&A 전담 설계 및 수학융합교육 컨텐츠 설계 및 교사교육',
                '글맥학원기획실장',
                '서울대학교사범대학교육학',
              ],
      },
      {
        location: '/images/ourteam/cfo.png',
        name: lang === 'en' ? 'Seungbum LIM' : '임승범',
        position: 'CFO',
        career:
          lang === 'en'
            ? [
                'CEO of One Metal Korea',
                'Vice CEO & CFO of Dongseo Resources',
                'Vice CEO & CFO of Dongseo Re&Tech',
                'Vice CEO & CFO of Daesin Resources',
                'Korea Development Corporation(Hyundai Group)',
                'Accounting Director',
              ]
            : [
                'METAVITY CFO',
                'Previously',
                '㈜원메탈코리아대표이사역임',
                '㈜동서자원, CFO 부사장',
                '㈜동서리엔텍CFO 부사장',
                '㈜대신자원CFO 부사장',
                '㈜현대그룹고려산업개발재정/회계부과장',
              ],
      },
      // {
      //   location: '/images/ourteam/chair.png',
      //   name: lang === 'en' ? 'Hainham Hwang' : '황혜남',
      //   position: 'CHAIRMAN',
      //   career:
      //     lang === 'en'
      //       ? [
      //           'Chairman, Realmeter (Current)',
      //           'CEO of Real Media (Current)',
      //           'Advisor, GBC Korea',
      //           'Vice Chairmna, Hurif',
      //           'CEO of IM Station',
      //           'CEO of M-People Communication',
      //           'BA in Fine Arts, Ewha Womans University',
      //         ]
      //       : [
      //           'METAVITY Chairman',
      //
      //           '(주)메타비티회장',
      //
      //           '(주)리얼미터회장(현)',
      //
      //           '(주)리얼미디어대표(현)',
      //
      //           '(주)지비시코리아고문',
      //
      //           '(주)휴리프부회장',
      //
      //           '(주)아이엠스테이션대표역임',
      //
      //           '(주)엠피플커뮤니케이션대표역임',
      //
      //           '이화여대미술대학학사',
      //         ],
      // },
    ]
  }, [lang])
  const sliderRef = useRef(null)
  const sizeX = useRefWidth(sliderRef)

  const [viewTarget, setViewTarget] = useState(0)
  const scroll = useRef<HTMLDivElement>(null)

  // computed
  // computed
  // computed

  const scrollMap = useMemo(() => {
    return _(images)
      .map((v, i) => ({ scroll: i * sizeX, index: i }))
      .keyBy('index')
      .value()
  }, [images, sizeX])

  // method
  // method
  // method

  const onClickNext = () => {
    setViewTarget((s) => {
      if (1 === s) {
        return 0
      }
      return s + 1
    })
  }
  const onClickPrev = () => {
    setViewTarget((s) => {
      if (s === 0) {
        return 1
      }
      return s - 1
    })
  }

  //onClick
  //onClick
  //onClick

  // effect
  // effect
  // effect

  useEffect(() => {
    if (!scroll) {
      return
    }
    if (scroll && scrollMap) {
      scroll.current!.scrollTo(scrollMap[viewTarget].scroll, 0)
    }
  }, [viewTarget, scrollMap])

  // renderMap
  // renderMap
  // renderMap

  const description = useMemo(() => {
    const career = images[viewTarget].career.map((v, i) => {
      return (
        <div key={`ourteam career ${i}`} className={classname(['description-career'], ['m-body2'])}>
          {`◼ ${v}`}
        </div>
      )
    })
    return (
      <div className={classname(['description'])}>
        <div className={classname(['description-image-wrapper'])}>
          <div
            className={classname(['description-image'])}
            ref={sliderRef}
            style={{ backgroundImage: `url(${images[viewTarget].location})`, backgroundSize: 'cover', height: sizeX * 1.23 }}
          />
          <div className={classname(['image-slider-control'])}>
            <div className={classname(['image-slider-state-wrapper'])}>
              <div className={classname(['image-slider-state1'], ['m-h4'])}>{viewTarget === 0 ? '01 ' : `0${viewTarget + 1} `}</div>
              <div className={classname(['image-slider-state2'], ['m-h4'])}> / 02</div>
            </div>
            <div className={classname(['description-arrow'])}>
              <img className={classname(['description-left'])} onClick={onClickPrev} src="/images/mission/left.png" alt="left" />
              <img className={classname(['description-right'])} onClick={onClickNext} src="/images/mission/right.png" alt="left" />
            </div>
          </div>
        </div>
        <div className={classname(['description-text-wrapper'])}>
          <div className={classname(['description-name'], ['m-subtitle1'])}>{images[viewTarget].name}</div>
          <div className={classname(['description-position'], ['m-body'])}>{images[viewTarget].position}</div>
          <div className={classname(['description-line'])}></div>
          <div className={classname(['description-career-wrapper'])}>{career}</div>
        </div>
      </div>
    )
  }, [images, sizeX, viewTarget])

  return (
    <div className={classname(['ourteam'])}>
      <div className={classname(['ourteam-left'])}>
        <div className={classname(['ourteam-title-wrapper'])}>
          <div className={classname(['ourteam-title'], ['m-subtitle'])}>Our Team</div>
          <div className={classname(['ourteam-subtitle'], ['m-body'])}>{lang === 'en' ? 'Metavity introducing the executives.' : '메타비티의 임원진을 소개합니다.'}</div>
        </div>

        <div className={classname(['image-slider-wrapper'])}>
          <div className={classname(['image-slider-center'])}>
            <div className={classname(['image-slider'])}>
              <div ref={scroll} className={classname('image-contents')}></div>
            </div>
          </div>
        </div>
      </div>
      <div className={classname(['ourteam-right'])}>{description}</div>
    </div>
  )
}
