import { useEffect, useState } from 'react'
import useIsLoad from './isLoad'

const useMobileDetect = () => {
  const load = useIsLoad()
  const [isMobile, setIsMobile] = useState(false)
  const [checkProcessing, setCehck] = useState(true)

  useEffect(() => {
    if (!load) return
    let agent = window.navigator.userAgent
    setIsMobile(agent.includes('Mobi'))
    setCehck(false)
  }, [load])

  return { checkProcessing, isMobile }
}

export default useMobileDetect
