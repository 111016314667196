import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { recoilLangState } from 'src/state/recoilLangState'
import { classOption } from 'src/utill/index'
import style from './index.module.scss'
const { classname } = classOption(style)
export default function MobileFooter() {
  const lang = useRecoilValue(recoilLangState)
  const socialData = useMemo(() => {
    return [
      {
        name: 'Facebook',
        link: 'https://www.facebook.com/metavityofficial',
      },
      {
        name: 'Twitter',
        link: 'https://twitter.com/metavityworld',
      },
      {
        name: 'Instagram',
        link: 'https://www.instagram.com/metavityofficial/',
      },
      {
        name: 'Linkedin',
        link: 'https://www.linkedin.com/company/metavityofficial/mycompany/',
      },
      {
        name: 'Youtube',
        link: 'https://www.youtube.com/channel/UCn0cce5_pFt3g96daN_4IFQ',
      },
      {
        name: 'KakaoTalk',
        link: 'http://pf.kakao.com/_YxnVGxj',
      },
    ]
  }, [])
  const onClickSocial = (url: string) => {
    return () => {
      window.open(url, '_blank')
    }
  }
  const doCopy = (text: string) => {
    // 흐음 1.
    if (navigator.clipboard) {
      // (IE는 사용 못하고, 크롬은 66버전 이상일때 사용 가능합니다.)
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert('클립보드에 복사되었습니다.')
        })
        .catch(() => {
          alert('복사를 다시 시도해주세요.')
        })
    }
  }

  //render
  //render
  //render
  const social = useMemo(() => {
    return socialData.map((v, i) => {
      return (
        <div className={classname(['footer-info-link-item'], ['m-body2'])} key={`social ${i}`} onClick={onClickSocial(v.link)}>
          <div className={classname(['footer-info-link-item-name'])}>{v.name}</div>
          <img className={classname(['footer-info-link-item-img'])} src="/images/footer/arrow.png" alt="arrow" />
        </div>
      )
    })
  }, [socialData])

  return (
    <div className={classname(['footer'])}>
      <div className={classname(['footer-back'])} style={{ backgroundImage: 'url(/images/footer/backMobile.png)' }}>
        <div className={classname(['footer-back-text', lang === 'en' ? 'en-width' : 'ko-width'], ['m-subtitle2'])}>
          {lang === 'en' ? `Metavity initiates the education ` : `꿈같던 교육의 서막`}
        </div>
        <div className={classname(['footer-back-text', lang === 'en' ? 'en-width' : 'ko-width'], ['m-subtitle2'])}>
          {lang === 'en' ? `that we dream of with virtual journey` : `메타비티에서 시작합니다`}
        </div>
      </div>
      <div className={classname(['footer-email-wrapper'])}>
        <div className={classname(['footer-email'])} onClick={() => doCopy('support@metavity.world')}></div>
      </div>
      <div className={classname(['footer-info'])}>
        <div className={classname(['footer-info-logo-wrapper'])}>
          <img className={classname(['footer-info-logo'])} src="/images/footer/logo.png" alt="email" />
          <div className={classname(['footer-info-logo-sub1'], ['m-subtitle3'])}>ⓒ2019-2024 Metavity Co.,Ltd. All Rights Reserved.</div>
          <div className={classname(['footer-info-logo-sub2'], ['m-body2'])}>
            Any unauthorized review, use, disclosure or distribution of the contents of this webpage is strictly prohibited
          </div>
        </div>
      </div>
      <div className={classname(['footer-info-bottom'])}>
        <div className={classname(['footer-info-container-wrap'])}>
          <div className={classname(['footer-info-wrapper'])}>
            <div className={classname(['footer-info-title'], ['m-body2'])}>Office address</div>
            <div className={classname(['footer-info-sub'], ['m-body2'])}>{lang === 'en' ? '4th floor, Teheran-ro 79-gil 25-1,' : '서울시 강남구 테헤란로 79길 25-1'}</div>
            <div className={classname(['footer-info-sub'], ['m-body2'])}>{lang === 'en' ? 'Gangnam-gu, Seoul, Republic of Korea' : '준빌딩 4층'}</div>
          </div>
          <div className={classname(['footer-info-wrapper'])}>
            <div className={classname(['footer-info-title'], ['m-body2'])}>Corporate registration number</div>
            <div className={classname(['footer-info-sub'], ['m-body2'])}>289-86-01305</div>
          </div>
          {/*<div className={classname(['footer-info-wrapper'])} style={{ display: lang === 'en' ? 'none' : '' }}>*/}
          {/*  <div className={classname(['footer-info-title'], ['m-body2'])}>(주담 문의)</div>*/}
          {/*  <div className={classname(['footer-info-sub'], ['m-body2'])}>010-7542-4954<div style={{ fontSize:'10px', lineHeight: '16px'}}>위임된 번호이며, 법률적인 책임은 위임자가 지기로 한다.</div></div>*/}
          {/*</div>*/}
          <div className={classname(['footer-info-wrapper'])}>
            <div className={classname(['footer-info-title'], ['m-body2'])}>FAX</div>
            <div className={classname(['footer-info-sub'], ['m-body2'])}>{lang === 'en' ? '+82-2-567-2889' : '02-567-2889'}</div>
          </div>
          <div className={classname(['footer-info-wrapper'])}>
            <div className={classname(['footer-info-title'], ['m-body2'])}>TEL</div>
            <div className={classname(['footer-info-sub'], ['m-body2'])}>{lang === 'en' ? '+82-2-567-2888,' : '02-567-2888'}</div>
          </div>
        </div>

        <div className={classname(['footer-info-link'])}>{social} </div>
      </div>
    </div>
  )
}
