import _ from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { recoilLangState } from 'src/state/recoilLangState'
import { classOption } from 'src/utill/index'
import style from './index.module.scss'

const { classname } = classOption(style)

export default function OurTeam() {
  // data
  // data
  // data
  const lang = useRecoilValue(recoilLangState)
  const images = useMemo(() => {
    return [
      {
        location: '/images/ourteam/ceo.png',
        name: lang === 'en' ? 'Ryan Byun' : '변원섭',
        position: lang === 'en' ? 'CEO' : '대표이사',
        career:
          lang === 'en'
            ? [
                'Education system and content design specialist',
                'SMILE Global Vice-President',
                'International Math Olympiad (WMO), Committee Member',
                'Previously',
                'CMS EDU, CD Learning M&A KOSDAQ IPO Design',
                'Math Convergence Education and Design',
                'GLMAC School, Planning Manager',
                'Seoul National University, College of Education',
              ]
            : [
                '한국 NFT 콘텐츠 협회 교육분과 위원장',
                'SMILE Global Vice-president',
                '세계수학올림피아드(WMO)조직위원회위원',
                'Previously',
                'CMS edu CSO',
                'CMS EDU, 청담러닝M&A 전담설계및수학융합교육컨텐츠설계및교사교육',
                '글맥학원기획실장',
                '서울대학교 사범대학 교육학',
              ],
      },
      {
        location: '/images/ourteam/cfo.png',
        name: lang === 'en' ? 'Seungbum LIM' : '임승범',
        position: lang === 'en' ? 'CFO' : '부사장',
        career:
          lang === 'en'
            ? [
                'CEO of One Metal Korea',
                'Vice CEO & CFO of Dongseo Resources',
                'Vice CEO & CFO of Dongseo Re&Tech',
                'Vice CEO & CFO of Daesin Resources',
                'Korea Development Corporation(Hyundai Group)',
                'Accounting Director',
              ]
            : [
                'METAVITY CFO',
                'Previously',
                '㈜원메탈코리아대표이사역임',
                '㈜동서자원, CFO 부사장',
                '㈜동서리엔텍CFO 부사장',
                '㈜대신자원CFO 부사장',
                '㈜현대그룹고려산업개발재정/회계부과장',
              ],
      }
      // {
      //   location: '/images/ourteam/chair.png',
      //   name: lang === 'en' ? 'Hainham Hwang' : '황혜남',
      //   position: lang === 'en' ? 'CHAIRMAN' : '회장',
      //   career:
      //     lang === 'en'
      //       ? [
      //         'Chairman, Realmeter (Current)',
      //         'CEO of Real Media (Current)',
      //         'Advisor, GBC Korea',
      //         'Vice Chairmna, Hurif',
      //         'CEO of IM Station',
      //         'CEO of M-People Communication',
      //         'BA in Fine Arts, Ewha Womans University',
      //       ]
      //       : [
      //         'METAVITY Chairman',
      //         '(주)메타비티회장',
      //         '(주)리얼미터회장(현)',
      //         '(주)리얼미디어대표(현)',
      //         '(주)지비시코리아고문',
      //         '(주)휴리프부회장',
      //         '(주)아이엠스테이션대표역임',
      //         '(주)엠피플커뮤니케이션대표역임',
      //         '이화여대 미술대학 학사',
      //       ],
      // },
    ]
  }, [lang])
  const sizeX = 275

  const [viewTarget, setViewTarget] = useState(0)
  const scroll = useRef<HTMLDivElement>(null)

  // computed
  // computed
  // computed

  const scrollMap = useMemo(() => {
    return _(images)
      .map((v, i) => ({ scroll: i * sizeX, index: i }))
      .keyBy('index')
      .value()
  }, [images, sizeX])

  // method
  // method
  // method

  const onClickNext = () => {
    setViewTarget((s) => {
      if (1 === s) {
        return 0
      }
      return s + 1
    })
  }
  const onClickPrev = () => {
    setViewTarget((s) => {
      if (s === 0) {
        return 1
      }
      return s - 1
    })
  }

  //onClick
  //onClick
  //onClick

  // effect
  // effect
  // effect

  useEffect(() => {
    if (!scroll) {
      return
    }
    if (scroll && scrollMap) {
      scroll.current!.scrollTo(scrollMap[viewTarget].scroll, 0)
    }
  }, [viewTarget, scrollMap])

  // renderMap
  // renderMap
  // renderMap

  const imagesRender = useMemo(() => {
    return images.map((v, i) => {
      return (
        <img
          key={`ourteam images render${i}`}
          className={classname([images[viewTarget].name === v.name ? 'image-item' : 'untarget-image-item'])}
          onClick={() => {
            setViewTarget(i)
          }}
          src={v.location}
          alt="about"
        />
      )
    })
  }, [images, viewTarget])

  const description = useMemo(() => {
    const career = images[viewTarget].career.map((v, i) => {
      return (
        <div key={`ourteam career ${i}`} className={classname(['description-career'], ['body3'])}>
          <div className={classname(['list'])}> {`◼  `}</div>
          <div className={classname(['text'], ['body3'])}> {v}</div>
        </div>
      )
    })
    return (
      <div className={classname(['description'])}>
        <div className={classname(['description-image-wrapper'])}>
          <img className={classname(['description-image'])} src={images[viewTarget].location} alt="img" />
        </div>
        <div className={classname(['description-text-wrapper'])}>
          <div className={classname(['description-name'], ['h3'])}>{images[viewTarget].name}</div>
          <div className={classname(['description-position'], ['body2'])}>{images[viewTarget].position}</div>
          <div className={classname(['description-line'])}>line</div>
          <div className={classname(['description-career-wrapper'])}>{career}</div>
        </div>
      </div>
    )
  }, [images, viewTarget])

  return (
    <div className={classname(['ourteam'])}>
      <div className={classname(['ourteam-left'])}>
        <div className={classname(['ourteam-title-wrapper'])}>
          <div className={classname(['ourteam-title'], ['body1'])}>Our Team</div>
          <div className={classname(['ourteam-subtitle'], ['h3'])}>{lang === 'en' ? 'Metavity introducing' : '메타비티 분야별'}</div>
          <div className={classname(['ourteam-subtitle'], ['h3'])}>{lang === 'en' ? 'the executives.' : '최고 전문가 집단'}</div>
        </div>

        <div className={classname(['image-slider-wrapper'])}>
          <div className={classname(['image-slider-control'])}>
            <div className={classname(['image-slider-state'])}>
              {viewTarget === 0 ? '01 ' : `0${viewTarget + 1} `}
              {` `}
              <div className={classname(['image-slider-state-all'])}>{` / 02`}</div>
            </div>
            <div className={classname(['description-arrow'])}>
              <img className={classname(['description-left'])} onClick={onClickPrev} src="/images/mission/left.png" alt="left" />
              <img className={classname(['description-right'])} onClick={onClickNext} src="/images/mission/right.png" alt="left" />
            </div>
          </div>
          <div className={classname(['image-slider-center'])}>
            <div className={classname(['image-slider'])}>
              <div ref={scroll} className={classname('image-contents')}>
                {imagesRender}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classname(['ourteam-right'])}>{description}</div>
    </div>
  )
}
