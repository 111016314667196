import _ from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { recoilLangState } from 'src/state/recoilLangState'
import { classOption } from 'src/utill/index'
import style from './index.module.scss'

const { classname } = classOption(style)

export default function Goal() {
  // data
  // data
  // data
  const lang = useRecoilValue(recoilLangState)
  const defaultDisplayCount = 4
  const [images] = useState([
    {
      location: '/images/goal/smile.png',
      sub: 'Stanford SMILE',
    },
    {
      location: '/images/goal/silicon.png',
      sub: 'Silicon Valley Math',
    },
    {
      location: '/images/goal/science.png',
      sub: 'Science & STEAM',
    },
    {
      location: '/images/goal/k-culture.png',
      sub: 'K-Culture, K-Pop & K-Food',
    },
    {
      location: '/images/goal/golf.png',
      sub: 'Golf & Sports for All',
    },
    {
      location: '/images/goal/government.png',
      sub: 'Government Accredited Computer Certificate',
    },
    {
      location: '/images/goal/iso.png',
      sub: 'ISO Disaster Safety Certificate',
    },
    {
      location: '/images/goal/multiple.png',
      sub: 'Multiple Language',
    },
    {
      location: '/images/goal/financial.png',
      sub: 'Financial Intelligence',
    },
    {
      location: '/images/goal/problem.png',
      sub: 'Problem Solving',
    },
  ])
  const sizeX = 275

  const [maxLength] = useState(images.length - defaultDisplayCount + 1)
  const [viewTarget, setViewTarget] = useState(0)
  const scroll = useRef<HTMLDivElement>(null)

  // computed
  // computed
  // computed

  const scrollMap = useMemo(() => {
    return _(images)
      .map((v, i) => ({ scroll: i * sizeX, index: i }))
      .keyBy('index')
      .value()
  }, [images, sizeX])

  // method
  // method
  // method

  const onClickNext = useMemo(() => {
    return _.throttle(() => {
      setViewTarget((s) => {
        if (maxLength - 1 <= s) {
          return s
        }
        return s + 1
      })
    }, 600)
  }, [maxLength])
  const onClickPrev = useMemo(() => {
    return _.throttle(() => {
      setViewTarget((s) => {
        if (s === 0) {
          return s
        }
        return s - 1
      })
    }, 600)
  }, [])

  //onClick
  //onClick
  //onClick

  // effect
  // effect
  // effect

  useEffect(() => {
    if (!scroll) {
      return
    }
    if (scroll && scrollMap) {
      scroll.current!.scrollTo(scrollMap[viewTarget].scroll, 0)
    }
  }, [viewTarget, scrollMap])

  // renderMap
  // renderMap
  // renderMap

  const imagesRender = useMemo(() => {
    return images.map((v, i) => {
      return (
        <div className={classname('image-item-wrapper')} key={`goal-slider key: ${i}`}>
          <div className={classname('image-item-box')}>
            <img className={classname('image-item')} src={v.location} alt="about" />
          </div>
          <div className={classname('image-item-text')}>{v.sub}</div>
        </div>
      )
    })
  }, [images])

  return (
    <div className={classname(['goal'])}>
      <div className={classname(['goal-title-wrapper'])}>
        <div>
          <div className={classname(['goal-subtitle'], ['h2'])}>Global hub in Education</div>
          <div className={classname(['goal-subtitle2'], ['body1'])}>
            {lang === 'en' ? `Acquire 100 Million Users by 2024 and 1 Billion Users by 2028` : `2024년까지 1억명, 2028년까지 10억 명의 사용자 확보`}
          </div>
        </div>
        <div>
          <div>
            <div className={classname(['goal-sub-wrapper'], ['body3'])}>
              <div className={classname(['goal-sub'], ['body3'])}>
                <div className={classname(['goal-sub-item'], ['body3'])}>{`• `}</div>
                <div className={classname(['goal-sub-item'], ['body3'])}>
                  {lang === 'en'
                    ? `Excellent Educational Contents & Services + Big data-based AI analysis & Recommendation Technology, Learner’s Behavior Data and Ensuring Data Autonomy of Learners
                  through Blockchain + Stanford SMILE`
                    : `우수한 교육 콘텐츠 및 서비스 + 빅데이터 기반 AI 분석 및 추천 기술, 학습자 행동 데이터 및 블록체인을 통한 학습자의 데이터 자율성 보장 + Stanford SMILE`}
                </div>
              </div>
              <div className={classname(['goal-sub'], ['body3'])}>
                <div className={classname(['goal-sub-item'], ['body3'])}>{`• `}</div>
                <div className={classname(['goal-sub-item'], ['body3'])}>
                  {lang === 'en'
                    ? `For the past 11 years, we have been working with local EdTech partners with competitiveness and market dominance in each region while conducting various global
                  activities and businesses.`
                    : `지난 11년간 다양한 글로벌 사업을 진행하며 현지에서 경쟁력과 시장지배력을 갖춘 에드테크 파트너들과 협력해 왔습니다.`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classname(['image-slider-wrapper'])}>
        <div className={classname(['image-slider-center'])}>
          <img className={classname(['description-left'])} onClick={onClickPrev} src="/images/goal/left.png" alt="left" />

          <div className={classname(['image-slider'])}>
            <div ref={scroll} className={classname('image-contents')}>
              {imagesRender}
            </div>
          </div>
          <img className={classname(['description-right'])} onClick={onClickNext} src="/images/goal/right.png" alt="left" />
        </div>
      </div>
    </div>
  )
}
