import { useRecoilValue } from 'recoil'
import { recoilLangState } from 'src/state/recoilLangState'
import { classOption } from 'src/utill/index'
import style from './index.module.scss'
const { classname } = classOption(style)
export default function Footer() {
  const lang = useRecoilValue(recoilLangState)
  const onClickSocial = (url: string) => {
    return () => {
      window.open(url, '_blank')
    }
  }
  const doCopy = (text: string) => {
    // 흐음 1.
    if (navigator.clipboard) {
      // (IE는 사용 못하고, 크롬은 66버전 이상일때 사용 가능합니다.)
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert('클립보드에 복사되었습니다.')
        })
        .catch(() => {
          alert('복사를 다시 시도해주세요.')
        })
    }
  }

  return (
    <div className={classname(['footer'])}>
      <div className={classname(['footer-back'])} style={{ backgroundImage: 'url(/images/footer/back.png)' }}>
        <div className={classname(['footer-back-text', lang === 'en' ? 'en-width' : 'ko-width'], ['h3'])}>
          {lang === 'en' ? `Metavity initiates the education` : `꿈같던 교육의 서막`}
        </div>
        <div className={classname(['footer-back-text', lang === 'en' ? 'en-width' : 'ko-width'], ['h3'])}>
          {lang === 'en' ? `that we dream of with virtual journey` : `메타비티에서 시작합니다`}
        </div>
      </div>
      <div className={classname(['footer-email-wrapper'])}>
        <div className={classname(['footer-email-left'])} onClick={() => doCopy('support@metavity.world')}></div>
        <div className={classname(['footer-email'])} onClick={() => doCopy('support@metavity.world')}></div>
        <div className={classname(['footer-email-right'])} onClick={() => doCopy('support@metavity.world')}></div>
      </div>
      <div className={classname(['footer-info'])}>
        <div className={classname(['footer-info-logo-wrapper'])}>
          <img className={classname(['footer-info-logo'])} src="/images/footer/logo.png" alt="email" />
          <img className={classname(['footer-info-logosub'])} src="/images/footer/logosub.png" alt="email" />
        </div>
        <div className={classname(['footer-info-container-wrap'])}>
          <div className={classname(['footer-info-container', 'first'])}>
            <div className={classname(['footer-info-wrapper'])}>
              <div className={classname(['footer-info-title'], ['body3'])}>Office address</div>
              <div className={classname(['footer-info-sub'], ['body3'])}>{lang === 'en' ? '4th floor, Teheran-ro 79-gil 25-1,' : '서울시 강남구 테헤란로 79길 25-1'}</div>
              <div className={classname(['footer-info-sub'], ['body3'])}>{lang === 'en' ? 'Gangnam-gu, Seoul, Republic of Korea' : '준빌딩 4층'}</div>
            </div>

            <div className={classname(['footer-info-wrapper'])}>
              <div className={classname(['footer-info-title'], ['body3'])}>Corporate registration number</div>
              <div className={classname(['footer-info-sub'], ['body3'])}>289-86-01305</div>
            </div>


            {/*<div className={classname(['footer-info-wrapper'])} style={{ display: lang === 'en' ? 'none' : '' }}>*/}
            {/*  <div className={classname(['footer-info-title'], ['body3'])}>(주담 문의)</div>*/}
            {/*  <div className={classname(['footer-info-sub'], ['body3'])}>010-7542-4954<div style={{ lineHeight: '22px'}}>위임된 번호이며, 법률적인 책임은 위임자가 지기로 한다.</div></div>*/}
            {/*</div>*/}
          </div>
          <div className={classname(['footer-info-container', 'second'])}>
            <div className={classname(['footer-info-wrapper'])}>
              <div className={classname(['footer-info-title'], ['body3'])}>TEL</div>
              <div className={classname(['footer-info-sub'], ['body3'])}>{lang === 'en' ? '+82-2-567-2888,' : '02-567-2888'}</div>
            </div>
            <div className={classname(['footer-info-wrapper'])}>
              <div className={classname(['footer-info-title'], ['body3'])}>FAX</div>
              <div className={classname(['footer-info-sub'], ['body3'])}>{lang === 'en' ? '+82-2-567-2889' : '02-567-2889'}</div>
            </div>
          </div>
        </div>

        <div className={classname(['footer-info-link'])}>
          <div className={classname(['footer-info-link-left'])}>
            <img
              className={classname(['footer-info-link-item'])}
              onClick={onClickSocial('https://www.facebook.com/metavityofficial')}
              src="/images/footer/facebook.png"
              alt="link"
            />
            <img className={classname(['footer-info-link-item'])} onClick={onClickSocial('https://twitter.com/metavityworld')} src="/images/footer/twitter.png" alt="link" />
            <img
              className={classname(['footer-info-link-item'])}
              onClick={onClickSocial('https://www.instagram.com/metavityofficial/')}
              src="/images/footer/insta.png"
              alt="link"
            />
          </div>
          <div className={classname(['footer-info-link-right'])}>
            <img
              className={classname(['footer-info-link-item'])}
              onClick={onClickSocial('https://www.linkedin.com/company/metavityofficial/mycompany/')}
              src="/images/footer/link.png"
              alt="link"
            />
            <img
              className={classname(['footer-info-link-item'])}
              onClick={onClickSocial('https://www.youtube.com/channel/UCn0cce5_pFt3g96daN_4IFQ')}
              src="/images/footer/youtube.png"
              alt="link"
            />
            <img className={classname(['footer-info-link-item'])} onClick={onClickSocial('http://pf.kakao.com/_YxnVGxj')} src="/images/footer/kakao.png" alt="link" />
          </div>
        </div>
      </div>
    </div>
  )
}
