import _ from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { recoilLangState } from 'src/state/recoilLangState'
import { classOption } from 'src/utill/index'
import style from './index.module.scss'
const { classname } = classOption(style)

export default function Platform() {
  //data
  //data
  //data
  const lang = useRecoilValue(recoilLangState)
  const platformContents = useMemo(() => {
    return [
      {
        location: '/images/platform/toest.png',
        icon: '/images/platform/toesti.png',
        name: 'TOEST',
        sub:
          lang === 'en'
            ? `TOEST aim to inspiore intellectual curiosity in every young mind and has a 
central theme each period.
Learners can participate in several testing such as GPST(Gloval Problem 
Solving Test), SMC (Silicon Valley Math Contest), etc. All are held online with 
any gadget`
            : `온라인 형태의 평가 플랫폼으로 종합적이고 융합적인 문제해결력 향상을 위한 질문과 일상생활에서 직면하는 상황이 중심이 되는 GPST와 수학적 문제 해결력과 컴퓨팅 사고력을 측정하는 SMC로 구성되어 있습니다.`,
        link: 'https://toest.me/',
      },
      {
        location: '/images/platform/e-test.png',
        icon: '/images/platform/e-testi.png',
        name: 'e~Test',
        sub:
          lang === 'en'
            ? `A nationally recognized proficiency test course for Office Automation(OA).
It is officially approved by the Ministry of ICT and Department of Education 
in Korea, Philippines, Vietnam.`
            : `정보화 사회에 필요한 정보소양, 정보기술능력과 컴퓨터 활용능력을 측정할 수 있는 한국, 베트남, 필리핀 정부 공인 인증 평가 시험`,
        link: 'https://etestph.toest.me/',
      },
      {
        location: '/images/platform/idsea.png',
        icon: '/images/platform/idseai.png',
        name: 'IDSEA',
        sub:
          lang === 'en'
            ? `ISO/IEC 17024 certification-based ISO Disaster Prevention Safety Profes-
sional course.
It aims to prevent disaster safety accidents through mandatory online and 
offline education, qualification tests, and qualification refresher education. 
It also fosters international experts with capacities such as responding to 
disasters, making restorations, and handling follow-up issues when disas-
ters occur and knowledge on disaster safety.`
            : `ISO/IEC 17024 인증 기반 ISO 재난예방안전관리사 과정으로 온·오프라인 의무교육과 자격시험, 자격 유지 보수 교육 등을 통해 재난안전사고를 예방하고 재난 발생 시 재난 대응, 복구, 사후 대응 등 역량과 재난 안전에 대한 지식을 갖춘 국제 전문가 양성`,
        link: '',
      },
      {
        location: '/images/platform/smileup.png',
        icon: '/images/platform/smileupi.png',
        name: 'Smile up',
        sub:
          lang === 'en'
            ? `(Stanford Mobile Inquiry-based Learning Environment) developed by 
Stanford University Graduate School of Education.
1M users Question/Response/Discussion Data→ AI-based Behavior Analysis 
and Curation.`
            : `미국 스탠포드대학원과 개발한 100만 사용자 질문/응답/토론 플랫폼 -> AI 기반 행동 분석 및 큐레이션`,
        link: '',
      },
      {
        location: '/images/platform/metasquare.png',
        icon: '/images/platform/metasquarei.png',
        name: 'METASQUARE',
        sub: `Metaverse Learning Environment for Schools or Educational Institutes Cus-
tomized Learning Environment.
Personalized Learning with AI-General Education, Job Training Whole Person 
Education, etc. Scenario Based Learning.`,
        link: '',
      },
    ]
  }, [lang])
  const sliderRef = useRef(null)
  const sizeX = 650
  const [viewTarget, setViewTarget] = useState(0)
  const scroll = useRef<HTMLDivElement>(null)

  // computed
  // computed
  // computed

  const scrollMap = useMemo(() => {
    return _(platformContents)
      .map((v, i) => ({ scroll: i * sizeX, index: i }))
      .keyBy('index')
      .value()
  }, [platformContents])
  // method
  // method
  // method
  const onClickNext = useMemo(() => {
    return _.throttle(() => {
      setViewTarget((s) => {
        if (platformContents.length - 1 <= s) {
          return 0
        }
        return s + 1
      })
    }, 600)
  }, [platformContents.length])

  const onClickBefore = useMemo(() => {
    return _.throttle(() => {
      setViewTarget((s) => {
        if (s <= 0) {
          return platformContents.length - 1
        }
        return s - 1
      })
    }, 600)
  }, [platformContents.length])

  const onClickSite = (url: string) => {
    return () => {
      window.open(url, '_blank')
    }
  }

  // effect
  // effect
  // effect

  useEffect(() => {
    if (!scroll) {
      return
    }
    if (scroll && scrollMap) {
      scroll.current?.scrollTo(scrollMap[viewTarget].scroll, 0)
    }
  }, [viewTarget, scrollMap])

  // renderMap
  // renderMap
  // renderMap

  const platformContentsRender = useMemo(() => {
    return platformContents.map((v, i) => {
      return (
        <div key={`mission image ${i}`} className={classname('platform-item-wrapper')}>
          <img className={classname('platform-icon')} src={v.icon} alt="about" />
          <img className={classname('platform-item')} src={v.location} alt="about" />
        </div>
      )
    })
  }, [platformContents])
  const dots = useMemo(() => {
    return platformContents.map((v, i) => {
      return (
        <div
          className={classname(['dot-wrapper'])}
          key={`${v.name}dots`}
          onClick={() => {
            setViewTarget(i)
          }}>
          <img className={classname([i === viewTarget ? 'dot' : 'opacity'])} src="/images/platform/on.png" alt="dot" />
        </div>
      )
    })
  }, [platformContents, viewTarget])
  const description = useMemo(() => {
    return (
      <div className={classname(['platform-description-wrapper'])}>
        <div>
          <div className={classname(['platform-description-name'], ['subtitle1'])}>{platformContents[viewTarget].name}</div>
          <div className={classname(['platform-description-sub'], ['body3'])}>{platformContents[viewTarget].sub}</div>
        </div>
        {platformContents[viewTarget].link !== '' && (
          <div className={classname(['platform-description-link'], ['caption'])} onClick={onClickSite(platformContents[viewTarget].link)}>
            Visit site
          </div>
        )}
      </div>
    )
  }, [platformContents, viewTarget])
  return (
    <div className={classname('platform')}>
      <div className={classname('platform-title-wrapper')}>
        <div className={classname(['platform-title'], ['h2'])}>Platform</div>
      </div>
      <div className={classname(['platform-slider-wrapper'])}>
        <div
          className={classname(['platform-slider'])}
          onScroll={(e) => {
            e.preventDefault()
          }}
          ref={sliderRef}>
          <div ref={scroll} className={classname('platform-slider-contents')}>
            {platformContentsRender}
          </div>
        </div>
        <div className={classname(['dot-container'])}>{dots}</div>
        <img className={classname(['platform-slider-button', 'left'])} onClick={onClickBefore} src="/images/platform/left.png" alt="left" />
        <img className={classname(['platform-slider-button', 'right'])} onClick={onClickNext} src="/images/platform/right.png" alt="left" />
      </div>
      <div className={classname(['platform-description'])}>{description}</div>
    </div>
  )
}
