import { useMemo } from 'react'
import { classOption } from 'src/utill/index'
import style from './index.module.scss'
const { classname } = classOption(style)

export default function MobileGlobalBranch() {
  const globalBranchData = useMemo(() => {
    return [
      {
        name: 'S.Korea',
        description: 'Head Quarter',
        flag: '/images/mobile/global-branch/korea.png',
        address: '4th floor, Teheran-ro 79-gil 25-1, Gangnam-gu, Seoul, Republic of Korea',
      },
      {
        name: 'USA',
        description: '',
        flag: '/images/mobile/global-branch/usa.png',
        address: '729 N 1st Street Silicon Valley, \nCA 95112',
      },
      {
        name: 'Indonesia',
        description: 'Metavitas Teknologi Indonesia',
        flag: '/images/mobile/global-branch/indonesia.png',
        address: 'Level 17, Sequise Tower SCBD JI. Jendral Sudirman No.71 Senayan, Kebayoran Baru, Jakarta Selatan 12190, Indonesia',
      },
      {
        name: 'Philippines',
        description: 'Metavity Philippines Corp.',
        flag: '/images/mobile/global-branch/philippines.png',
        address: 'Suite 501 Pasda Mansion Panay Avenue Cor Timog Ave South Triangle 1100 Quezon City NCR, Second District, Philippines',
      },
      {
        name: 'Singapore',
        description: '',
        flag: '/images/mobile/global-branch/singapore.png',
        address: '111 Somerset Road #06-07E 111 Somerset Singapore 238164',
      },
    ]
  }, [])

  const globalBranchMap = useMemo(() => {
    return globalBranchData.map((v, i) => {
      return (
        <div className={classname(['mobile-global-country'])} key={`Mobile globalBranchMapping-${i}`}>
          <img className={classname(['country-flag'])} src={v.flag} alt="flag" />
          <div className={classname(['country-info'])}>
            <div className={classname(['country-text-container'])}>
              <div className={classname(['country-name'], ['m-subtitle2'])}>{v.name}</div>
              <div className={classname(['country-description'], ['m-body2'])}>{v.description}</div>
            </div>
            <div className={classname('line')} />
            <div className={classname(['country-address'], ['m-body2'])}>{v.address}</div>
          </div>
        </div>
      )
    })
  }, [globalBranchData])

  return (
    <div className={classname('mobile-global-branch')}>
      <div className={classname('mobile-global-main-container')} style={{ backgroundImage: 'url(/images/mobile/global-branch/main.png)', backgroundSize: 'cover' }}>
        <div className={classname(['global-branch-title'], ['m-subtitle'])}>Global Branch</div>
      </div>
      <div className={classname(['mobile-global-country-wrapper'])} style={{ backgroundImage: 'url(/images/global/backMobile.png)' }}>
        {globalBranchMap}
      </div>
    </div>
  )
}
