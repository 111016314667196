import _ from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { recoilLangState } from 'src/state/recoilLangState'
import { classOption } from 'src/utill/index'
import style from './index.module.scss'

const { classname } = classOption(style)

export default function Mission() {
  // data
  // data
  // data
  const lang = useRecoilValue(recoilLangState)
  const [isClicked, setIsClicked] = useState(false)
  const images = useMemo(() => {
    return [
      {
        key: '1',
        location: '/images/mission/teaching.png',
        name: 'Teaching',
        numberLocation: '/images/mission/01.png',
        sub: lang === 'en' ? 'Encouraging future growth mindset in a digital era with cutting edge technology' : `IT 최첨단 기술로 디지털 시대의 미래역량을 가르칩니다`,
      },
      {
        key: '2',
        location: '/images/mission/learning.png',
        name: 'Learning',
        numberLocation: '/images/mission/02.png',
        sub:
          lang === 'en'
            ? 'Delivering effective learning by optimizing synchronous and asyn-chronous learning strategies'
            : '동기와 비동기식 전략을 통해 효과적인 러닝방법을 제공합니다.',
      },
      {
        key: '3',
        location: '/images/mission/sharing.png',
        name: 'Sharing',
        numberLocation: '/images/mission/03.png',
        sub:
          lang === 'en'
            ? 'Putting publishing into the hands of learners. Nurture creativity and collaboration with publishing'
            : '단순 학습자에서 나아가 공간을 누리는 모두가 협업을 통해 창의적 생산력을 키우고 콘텐츠 재생산에도 참여합니다.',
      },
    ]
  }, [lang])

  const sliderRef = useRef(null)
  const sizeX = 760

  const [viewTarget, setViewTarget] = useState(0)
  const scroll = useRef<HTMLDivElement>(null)

  // computed
  // computed
  // computed

  const scrollMap = useMemo(() => {
    return _(images)
      .map((v, i) => ({ scroll: i * sizeX, index: i }))
      .keyBy('index')
      .value()
  }, [images, sizeX])

  // method
  // method
  // method

  const onButtonClick = (fn: Function) => {
    return () => {
      setIsClicked(true)

      fn()
    }
  }

  const onClickNext = useMemo(() => {
    return _.throttle(() => {
      setViewTarget((s) => {
        if (images.length - 1 <= s) {
          return 0
        }
        return s + 1
      })
    }, 600)
  }, [images.length])

  const onClickBefore = useMemo(() => {
    return _.throttle(() => {
      setViewTarget((s) => {
        if (s <= 0) {
          return images.length - 1
        }
        return s - 1
      })
    }, 600)
  }, [images.length])

  // effect
  // effect
  // effect

  useEffect(() => {
    if (!scroll) {
      return
    }
    if (scroll && scrollMap) {
      scroll.current?.scrollTo(scrollMap[viewTarget].scroll, 0)
    }
  }, [viewTarget, scrollMap])

  useEffect(() => {
    const time = 5000
    let target: NodeJS.Timeout
    const fn = () => {
      onClickNext()
      target = setTimeout(fn, time)
    }
    if (isClicked) {
      target = setTimeout(() => {
        fn()
        setIsClicked(false)
      }, 10000)
    } else {
      target = setTimeout(fn, time)
    }

    return () => {
      clearTimeout(target)
    }
  }, [isClicked, onClickNext])

  // renderMap
  // renderMap
  // renderMap

  const imagesRender = useMemo(() => {
    return images.map((v, i) => {
      return <img className={classname('image-item')} key={`mission image ${i}`} src={v.location} alt="about" />
    })
  }, [images])

  const description = useMemo(() => {
    return (
      <div className={classname(['description'])}>
        <img className={classname(['description-number'])} src={images[viewTarget].numberLocation} alt={images[viewTarget].name} />
        <div className={classname(['text-wrapper'])}>
          <div className={classname(['description-name'], ['subtitle1'])}>{images[viewTarget].name}</div>
          <div className={classname(['description-sub'], ['body3'])}>{images[viewTarget].sub}</div>
        </div>
        <div className={classname(['description-arrow'])}>
          <img className={classname(['description-left'])} onClick={onButtonClick(onClickBefore)} src="/images/mission/left.png" alt="left" />
          <img className={classname(['description-right'])} onClick={onButtonClick(onClickNext)} src="/images/mission/right.png" alt="left" />
        </div>
      </div>
    )
  }, [images, onClickBefore, onClickNext, viewTarget])

  const dots = useMemo(() => {
    return images.map((v, i) => {
      return (
        <div
          className={classname(['dot-wrapper'])}
          key={`${v.key}dots`}
          onClick={() => {
            setViewTarget(i)
          }}>
          <img className={classname(['dot'])} src="/images/mission/dot.png" alt="dot" />
          <div className={classname([i === viewTarget ? 'text-bold' : 'text-opacity'], [i === viewTarget ? 'subtitle2' : 'subtitle3'])}>{v.name}</div>
        </div>
      )
    })
  }, [images, viewTarget])

  return (
    <div className={classname(['mission'])}>
      <div className={classname(['mission-title-wrapper'])}>
        <div className={classname(['mission-title'], ['body1'])}>Mission</div>
        <div className={classname(['mission-subtitle'], ['h2'])}>Paradigm Shift in Education</div>
      </div>

      <div className={classname(['image-slider-wrapper'])}>
        <div className={classname(['image-slider-center'])}>
          <div className={classname('dots-navigation')}>{dots}</div>
          <div className={classname(['image-slider-container'])}>
            <div className={classname(['image-slider'])} ref={sliderRef}>
              <div ref={scroll} className={classname('image-contents')}>
                {imagesRender}
              </div>
            </div>
          </div>
          {description}
        </div>
      </div>
    </div>
  )
}
