import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { recoilLangState } from 'src/state/recoilLangState'
import { classOption } from 'src/utill/index'
import style from './index.module.scss'
const { classname } = classOption(style)

export default function MobileCompanySummary() {
  const lang = useRecoilValue(recoilLangState)
  const CompanySummaryData = useMemo(() => {
    return [
      {
        title: lang === 'en' ? 'Company Name' : '회사명',
        description: lang === 'en' ? 'METAVITY Co., Ltd.' : '(주)메타비티',
      },
      {
        title: 'CEO',
        description: lang === 'en' ? 'Ryan Byun' : '변원섭',
      },
      {
        title: lang === 'en' ? 'Date of incorporation' : '법인설립일',
        description: lang === 'en' ? 'March 14th, 2019' : '2019년 3월 14일',
      },
      {
        title: lang === 'en' ? 'Company Valuation' : '기업가치',
        description: lang === 'en' ? 'Appr. USD 153,000,000 (August 1st, 2022)' : '약 1.53억 달러 (22.08 기준)',
      },
      {
        title: lang === 'en' ? 'No. of Patent' : '특허',
        description: lang === 'en' ? '63 patents (some pending)' : '63개 (출원 포함)',
      },
      {
        title: lang === 'en' ? 'Office address' : '주소',
        description: lang === 'en' ? '3-4th floor, Teheran-ro 8-gil, Gangnam-gu, Seoul, Republic of Korea' : '대한민국, 서울 강남구 테헤란로 79번길 25-1, 준빌딩 4층',
      },
      {
        title: lang === 'en' ? 'Service Area' : '서비스분야',
        description: lang === 'en' ? 'Software/Platform and Educational Contents Development' : '소프트웨어/플랫폼, 교육콘텐츠 개발',
      },
      {
        title: lang === 'en' ? 'Main Service' : '주요서비스',
        description: lang === 'en' ? 'Metaverse Platform (e-Learning, e-Government)' : '메타버스 플랫폼 (e-Learning, e-Goverment)',
      },
    ]
  }, [lang])

  const CompanySummaryMap = useMemo(() => {
    return CompanySummaryData.map((v, i, o) => {
      let isFirst = i === 0
      let isLast = o.length - 1 === i
      return (
        <div className={classname(['mobile-company-summary-map', { last: isLast }, { first: isFirst }])} key={`Mobile globalBranchMapping-${i}`}>
          <div className={classname(['title'], ['m-subtitle3'])}>{v.title}</div>
          <div className={classname(['subtitle'], ['m-body2'])}>{v.description}</div>
        </div>
      )
    })
  }, [CompanySummaryData])

  return (
    <div className={classname('mobile-company-summary')}>
      <div className={classname(['company-summary-main-title'], ['m-subtitle'])}>Company Summary</div>
      <div className={classname(['company-summary-main-subtitle'], ['m-body2'])}>
        {lang === 'en'
          ? `Global leading web 3.0 platform company based on the excellent educational contents established in 2019`
          : `우수 교육 콘텐츠를 기반으로 한 글로벌 웹 3.0 플랫폼 기업`}
      </div>

      <div className={classname(['summary-container'])}>{CompanySummaryMap}</div>
    </div>
  )
}
