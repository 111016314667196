import { useRecoilValue } from 'recoil'
import { recoilLangState } from 'src/state/recoilLangState'
import { classOption } from 'src/utill/index'
import style from './index.module.scss'

const { classname } = classOption(style)

export default function Pagination({ total, limit, page, setPage }) {
  // data
  // data
  // data

  const lang = useRecoilValue(recoilLangState)

  const numPages = Math.ceil(total / limit);

  return (
    <>

      {total !== 0 &&
        <div className={classname(['pagination'])}>

        <button className={classname(['page-nav'])}
                type={'button'}
                onClick={() => setPage(page - 1)}
                disabled={page === 1}>&lt;</button>

        <div className={classname(['page-list'])}>
          {Array(numPages)
            .fill()
            .map((_, i) => (
              <button type={'button'}
                      key={i + 1}
                      onClick={() => setPage(i + 1)}
                      aria-current={page === i + 1 ? 'page' : undefined}
              >
                {i + 1}
              </button>
            ))}

        </div>


        <button className={classname(['page-nav'])}
                type={'button'}
                onClick={() => setPage(page + 1)}
                disabled={page === numPages}>&gt;</button>
      </div>
      }
    </>
  )


}
