import _ from 'lodash'
import { HmacSHA256, SHA256 } from 'crypto-js'

type option_obj = { [x: string]: boolean }
type option_arr = (undefined | string | number | option_obj)[]
type option = undefined | string | number | option_arr | option_obj

export function classOption(style: { [x: string]: string } = {}) {
  return {
    classname: (option: option = '', notOption: option = '') => {
      function optionType(_option: option = '') {
        let result: string[] = []
        switch (typeof _option) {
          case 'string': {
            result.push(..._option.split(' '))
            break
          }
          case 'number': {
            result.push(_option.toString())
            break
          }
          case 'object': {
            if (!Array.isArray(_option)) {
              let temp = _(_option)
                .toPairs()
                .filter(([x, bool]) => bool)
                .map(([x, bool]) => x)
                .value()

              result.push(...temp)
            } else {
              result.push(...classOptionArr(_option))
            }
            break
          }
          default: {
            break
          }
        }
        return result
      }

      let result = optionType(option)

      let temp = _.chain(result)
        .map((v) => (style[v] ? `${style[v]}` : v))
        .join(' ')
        .split(' ')
        .union()
        .join(' ')
        .value()
      return notOption && optionType(notOption).length ? `${temp} ${optionType(notOption).join(' ')}` : temp
    },
  }
}

function classOptionArr(optionArr: option_arr) {
  let result: string[] = []
  optionArr.forEach((v) => {
    switch (typeof v) {
      case 'string': {
        result.push(v)
        break
      }
      case 'number': {
        result.push(v.toString())
        break
      }
      case 'object': {
        if (!Array.isArray(v)) {
          let temp = _(v)
            .toPairs()
            .filter(([x, bool]) => bool)
            .map(([x, bool]) => x)
            .value()

          result.push(...temp)
        }
        break
      }
      default: {
        break
      }
    }
  })

  return result
}

export function enterToBr(str: string) {
  const rand = _.random(1000, 9999)
  let result = str.split('\n').map((v, i) => (
    <span key={HmacSHA256(`${rand}-${i}`, SHA256(str).toString().slice(0, 5)).toString()}>
      <span>{v}</span>
      <br />
    </span>
  ))
  return <>{result}</>
}
