import { useRecoilState } from 'recoil'
import { recoilLangState } from 'src/state/recoilLangState'
import { classOption } from 'src/utill/index'
import style from './index.module.scss'
const { classname } = classOption(style)

export default function MobileHeader() {
  const [lang, setLang] = useRecoilState(recoilLangState)
  const onHomeClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  return (
    <div className={classname(['mobile-header'])}>
      <img src="/images/mobile/logo.png" alt="" className={classname(['logo'])} onClick={onHomeClick} />

      <div className={classname(['lang-wrapper', 'caption'])}>
        <div
          className={classname([lang === 'ko' ? 'bold' : 'lang'])}
          onClick={() => {
            setLang('ko')
          }}>
          KOR
        </div>
        <div
          className={classname([lang === 'en' ? 'bold' : 'lang'])}
          onClick={() => {
            setLang('en')
          }}>
          ENG
        </div>
      </div>
    </div>
  )
}
