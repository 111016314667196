import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { recoilLangState } from 'src/state/recoilLangState'
import { classOption } from 'src/utill/index'
import style from './index.module.scss'
const { classname } = classOption(style)

export default function Summary() {
  const lang = useRecoilValue(recoilLangState)
  //data
  //data
  //data
  const summaryList = useMemo(() => {
    return [
      {
        title1: lang === 'en' ? 'Company Name' : '회사명',
        sub1: lang === 'en' ? 'METAVITY Co., Ltd.' : '(주) 메타비티',
        title2: 'CEO',
        sub2: lang === 'en' ? 'Ryan Byun' : '변원섭',
        title3: lang === 'en' ? 'Date of incorporation' : '법입 설립일',
        sub3: lang === 'en' ? 'March 14th, 2019' : '2019년 3월 14일',
      },
      {
        title1: lang === 'en' ? 'No. of Patent' : '특허',
        sub1: lang === 'en' ? '63 patents (some pending)' : '63개 (출원 포함)',
        title2: lang === 'en' ? 'Office address' : '주소',
        sub2: lang === 'en' ? '4th floor, Teheran-ro 79-gil 25-1, Gangnam-gu, Seoul, Republic of Korea' : '대한민국, 서울 강남구 테헤란로 79번길 25-1, 준빌딩 4층',
        title3: lang === 'en' ? 'Service Area ' : '서비스분야',
        sub3: lang === 'en' ? 'Software/Platform and Educational Contents Development' : '소프트웨어/플랫폼, 교육콘텐츠 개발',
      },
    ]
  }, [lang])
  //render
  //render
  //render
  const summaryContents = useMemo(() => {
    return summaryList.map((v, i) => {
      return (
        <div className={classname(['summary-content-wrapper'])} key={`summaryContents ${i}`}>
          <div className={classname(['summary-content'])}>
            <div className={classname(['summary-content-title'], ['caption'])}>{v.title1}</div>
            <div className={classname(['summary-content-sub'], ['body3'])}>{v.sub1}</div>
          </div>
          <div className={classname(['summary-content'])}>
            <div className={classname(['summary-content-title'], ['caption'])}>{v.title2}</div>
            <div className={classname(['summary-content-sub'], ['body3'])}>{v.sub2}</div>
          </div>
          <div className={classname(['summary-content'])}>
            <div className={classname(['summary-content-title'], ['caption'])}>{v.title3}</div>
            <div className={classname(['summary-content-sub'], ['body3'])}>{v.sub3}</div>
          </div>
        </div>
      )
    })
  }, [summaryList])
  return (
    <div className={classname(['summary'])} style={{ backgroundImage: `url(/images/summary/back.png)` }}>
      <div className={classname(['summary-title-wrapper'])}>
        <div className={classname(['summary-title'], ['h2'])}>Company Summary</div>
        <div className={classname(['summary-subtitle'], ['body3'])}>
          {lang === 'en' &&
            `Global leading web 3.0 platform company based on the excellent
educational contents established in 2019`}
        </div>
      </div>
      <div className={classname(['summary-content-container'])}>{summaryContents}</div>
    </div>
  )
}
