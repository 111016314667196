import { useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import MainSlider from 'src/components/main/main-slider'
import { recoilLangState } from 'src/state/recoilLangState'
import { classOption } from 'src/utill/index'
import style from './index.module.scss'
const { classname } = classOption(style)

export default function MobileMain() {
  const lang = useRecoilValue(recoilLangState)
  const onClickSocial = (url: string) => {
    return () => {
      window.open(url, '_blank')
    }
  }

  const [socialList] = useState([
    { name: 'facebook', location: '/images/mobile/main/facebook.png', link: 'https://www.facebook.com/metavityofficial' },
    { name: 'twitter', location: '/images/mobile/main/twitter.png', link: 'https://twitter.com/metavityworld' },
    { name: 'youtube', location: '/images/mobile/main/youtube.png', link: 'https://www.youtube.com/channel/UCn0cce5_pFt3g96daN_4IFQ' },
    { name: 'kakaotalk', location: '/images/mobile/main/kakao.png', link: 'http://pf.kakao.com/_YxnVGxj' },
    { name: 'instagram', location: '/images/mobile/main/insta.png', link: 'https://www.instagram.com/metavityofficial/' },
    { name: 'linkedin', location: '/images/mobile/main/linkedin.png', link: 'https://www.linkedin.com/company/metavityofficial/mycompany/' },
  ])

  const socialListMap = useMemo(() => {
    return socialList.map((v, i) => {
      return <img className={classname(['icon'])} onClick={onClickSocial(v.link)} src={v.location} alt="icon" key={`mobile-icon ${i}`} />
    })
  }, [socialList])

  return (
    <div className={classname(['mobile-main'])}>
      <div className={classname(['main-bg-container'])}>
        <img className={classname(['main-bg'])} src="/images/mobile/main/background.png" alt="main" />
      </div>
      <div className={classname('main-bg-contents')}>
        <div className={classname(['text-1'], ['m-subtitle2'])}>Ever wondered what your brain can do?</div>
        <div className={classname(['text-2'], ['h3'])}>
          Discover <br />
          with us!
        </div>

        <div className={classname(['mobile-slider-wrapper'])}>
          <MainSlider className={classname(['mobile-slider'])} gap={50} />
        </div>

        <div className={classname(['text-3'], ['m-body'])}>
          {lang === 'en'
            ? `Metavity is the world’s first \nweb 3.0 platform company where it combines\n metaverse, AI, Blockchain & NFT systems.`
            : `메타비티는 메타버스, AI 블록체인, NFT 시스템이 결합된 \n세계 최초의 웹 3.0 플랫폼 교육 기업입니다.`}
        </div>
        <div className={classname(['icon-container'])}>{socialListMap}</div>
      </div>
    </div>
  )
}
