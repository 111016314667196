import _ from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import useIsLoad from 'src/hooks/commons/isLoad'
import { classOption } from 'src/utill'
import style from './index.module.scss'
import MainSlider from './main-slider'
const { classname } = classOption(style)

export default function Main() {
  const isLoad = useIsLoad()
  const starsRef = useRef<HTMLImageElement[]>([])
  const [link] = useState([
    { src: '/images/main/facebook.svg', alt: 'facebook', link: 'https://www.facebook.com/metavityofficial' },
    { src: '/images/main/instagram.svg', alt: 'instagram', link: 'https://www.instagram.com/metavityofficial/' },
    { src: '/images/main/kakao.svg', alt: 'kakao', link: 'http://pf.kakao.com/_YxnVGxj' },
    { src: '/images/main/linkedin.svg', alt: 'linkedin', link: 'https://www.linkedin.com/company/metavityofficial/mycompany/' },
    { src: '/images/main/tweet.svg', alt: 'tweet', link: 'https://twitter.com/metavityworld' },
    { src: '/images/main/youtube.svg', alt: 'youtube', link: 'https://www.youtube.com/channel/UCn0cce5_pFt3g96daN_4IFQ' },
  ])

  const socialLink = useMemo(() => {
    return link.map((v) => {
      return (
        <a href={v.link} target="_blank" rel="noreferrer" key={`social-link-${v.alt}`}>
          <img src={v.src} alt={v.alt} />
        </a>
      )
    })
  }, [link])

  const starElements = useMemo(() => {
    let smallList = [1, 4, 5, 6, 8, 9]

    return _.range(9).map((v) => {
      let isSmall = smallList.includes(v + 1)
      return (
        <img
          className={classname(['star', `star${v + 1}`, { small: isSmall }])}
          ref={(el) => {
            starsRef.current[v] = el as Exclude<typeof el, null>
          }}
          src="/images/main/star.svg"
          alt="star"
        />
      )
    })
  }, [])

  useEffect(() => {
    if (!isLoad) return
    starsRef.current.forEach((v) => {
      let randInt = _.random(500, 1500, false)
      let randInt2 = _.random(1000, 1500, false)
      v.style.animationDelay = `${randInt}ms`
      v.style.animationDuration = `${randInt2}ms`
    })
  }, [isLoad])

  return (
    <div className={classname(['container'])}>
      <img className={classname(['background'])} src="/images/main/background.png" alt="background" />

      <div className={classname(['stars'])}>{starElements}</div>

      <div className={classname(['foreground'])}>
        <div className={classname(['main-contents'])}>
          <div className={classname(['text'])}>
            <p className={classname(['big'])}>Discover with us!</p>
            <p className={classname(['normal'])}>Ever wondered what your brain can do?</p>
            <div>
              <p>Metavity is the world’s first web 3.0 platform company</p>
              <p>where it combines metaverse, AI, Blockchain & NFT systems.</p>
            </div>
          </div>

          <div className={classname(['interaction'])}>
            <div className={classname(['flare-container'])}>
              <img className={classname(['flare-load'])} src="/images/main/flare_load.svg" alt="flare_load" />
              <img className={classname(['flare'])} src="/images/main/flare.svg" alt="flare" />
            </div>

            <MainSlider className={classname(['slider'])} />
          </div>
        </div>
        <div className={classname(['socialList'])}>{socialLink}</div>
      </div>
    </div>
  )
}
