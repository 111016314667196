import { classOption } from '../utill'
import style from './index.module.scss'

import Mission from '../components/mission'
import Header from '../components/header'
import Main from '../components/main'
import AboutUs from '../components/aboutus'
import Goal from '../components/goal'
import Summary from '../components/summary'
import Global from '../components/global'
import Platform from '../components/platform'
import OurTeam from '../components/ourteam'
import News from '../components/news'
import Footer from '../components/footer'
// import Modal from 'src/components/modal'
import '../components/viewer/viewer.css'


import Viewer from 'viewerjs'
import { useEffect } from 'react'
import Modal from '../components/modal'


const { classname } = classOption(style)


const RootPage = () => {

  // useEffect(() => {
  //   const viewer = new Viewer(document.getElementById('modal') as HTMLElement, {
  //     // 여기에 Viewer.js 옵션을 추가할 수 있습니다.
  //   });
  //
  //   return () => {
  //     viewer.destroy();
  //   }
  // }, [])


  return (


    <div className={classname(['container'])}>
      <div className={classname(['app'])}>
        <Header />
        <Main />
        <AboutUs />
        <News />
        <Mission />
        <Goal />
        <Global />
        <Summary />
        <Platform />
        <OurTeam />
        <Footer />
      </div>

      {/*{*/}
      {/*<Modal>*/}
      {/*  <div id="modal" className={classname('modal-txt')} style={{maxWidth: '800px'}}>*/}

      {/*    /!*<div className={classname('modal-txt-group')} data-modal="modal1">*!/*/}
      {/*    /!*  <div className={classname('modal-txt-contents')}>*!/*/}
      {/*    /!*    [긴급 공지]<br/><br/>*!/*/}

      {/*    /!*    최근 불법적인 업체로부터 주식 사기 판매 사례가 빈번하게 일어나고 있어서 회사가 피해를 입고 있습니다. 내용 증명서 등 사문서를 위조하고 상장 확정된 내용으로 허위 기사를 임의로 내고 투자 권유를 하는 등의 회사와 무관하게 주식 거래를 유도해 피해자들을 만들고 있습니다. 회사에서 공식적으로 기관과 개인에게 투자를 받고 있는 주당 3,000원 초과의 거래는 사기 거래일 가능성이 매우 큽니다. 현재 사측은 불법적인 업체들이 확인되는 대로 고소, 고발을 진행 중에 있으며 무관용 원칙을 적용하여 강경 대응 조치를 취하고 있습니다. 허위 기사를 낸 언론사와 기자는 내용 증명을 보내고 고소를 진행 중에 있습니다. 위의 피해 사례와 본사는 전혀 무관함을 다시 한번 알려드리며, 위의 피해 사례에 대해 집단소송을 진행하는 법무법인이 있으나 피해자들과 확인해 본 결과, 선결제로 사례비를 요구하고 고소장 접수만 대리할 뿐 소송 대상을 특정할 수 없기 때문에 소송 진행도 어렵다는 것이 확인되었습니다. 이 사건은 경찰서에서 불법 업체들을 추적조사하고 있는 중인 사건입니다. 개별적으로 고소하시는 것이 추가적인 비용 지출으로 2차, 3차 피해를 입는 것을 막는 것임을 알려드립니다.*!/*/}


      {/*    /!*  </div>*!/*/}

      {/*    /!*  <div className={classname('modal-menu')}>*!/*/}
      {/*    /!*    <a href="#none" className={classname('modal-close-btn')}>닫기</a>*!/*/}
      {/*    /!*  </div>*!/*/}

      {/*    /!*</div>*!/*/}

      {/*    /!*<div className={classname('modal-txt-group')} data-modal="modal2">*!/*/}
      {/*    /!*  <div className={classname('modal-txt-contents')} style={{maxHeight: '75vh', padding:0}}>*!/*/}
      {/*    /!*    <img src="/images/popup/popup_231229.jpg" style={{maxWidth: '900px' , width: '100%'}} alt=""/>*!/*/}
      {/*    /!*  </div>*!/*/}
      {/*    /!*</div>*!/*/}

      {/*    <div className={classname('modal-txt-group')} data-modal="modal3">*/}
      {/*      <div className={classname('modal-txt-contents')} style={{maxHeight: '85vh', padding:0}}>*/}
      {/*        <img id="image" src="/images/popup/popup_240617.jpg" style={{maxWidth: '900px' , width: '100%'}} alt=""/>*/}
      {/*      </div>*/}
      {/*    </div>*/}





      {/*    /!*<img style={{ maxWidth: '30%', maxHeight: '30%', cursor: 'pointer', borderRadius: '20px' }} src={'images/modal/sul.jpg'} alt="modalImg" />*!/*/}
      {/*  </div>*/}
      {/*</Modal>*/}
      {/*}*/}

    </div>
  )
}



export default RootPage


